














































































































































































import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from 'vue-class-component';
import { BrbpPprWrapper } from "@/modules/budget-request/components/js/brbp-wrapper";
import { BrbpPee } from '@/modules/budget-request/components/js/pee';
import BudgerRequestMixin from '../mixins/bp-mixin';

@Component({
    name: 'c-table-budget-costs',
    filters: {
        addRanks: (value: any) => {
            if (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }
        }
    }
})
export default class CTableBudgetCosts extends mixins(BudgerRequestMixin)  {
    @Prop({
        required: true,
        default: {}
    })
    private filter!: any;

    @Prop({
        type: [],
        required: true
    })
    private data!: BrbpPprWrapper;

    @Prop({
        required: true,
    })
    private itputState!: string | null;

    @Prop({
        required: true,
    })
    private conclState!: string | null;

    @Prop({
        required: true
    })
    private getCellText!: Function;

    @Prop({
        required: true
    })
    private keyPress!: Function;

    @Prop({
        required: true,
        default: false
    })
    private ifRegion!: boolean;
    
    @Prop({
        required: true,
        default: false
    })
    private curBpState!: boolean;

    @Prop({
        required: true
    })
    private toggleChilds!: Function;

    @Prop({
        required: true
    })
    private addBibObjectIntoTable!: Function;

    @Prop({
        required: true
    })
    private costTopOffset!: string;

    @Prop({
        required: true
    })
    private setOffset!: Function;

    @Prop({
        required: true
    })
    private localName!: Function;

    @Prop({
        required: true
    })
    private conclAttributes1!: any[];

    @Prop({
        required: false,
        default: false
    })
    private conclMode!: boolean;
    
    @Prop({
        required: true
    })
    private numberOfDecimalPlacesProp!: number 
    // пришлось протащить тк когда брал из микисина не работала реактивность 
    // и значение numberOfDecimalPlace не менялось

    private created() {
        this.$watch('data.driData', this.prepareTabledata, { deep: true });
        this.$watch('filter.pgr.ppr', this.prepareTabledata, { deep: true });
        this.$watch('ifRegion', this.prepareTabledata) //this.forceToggleOpenAll);
        this.$watch('numberOfDecimalPlacesProp', this.prepareTabledata) //this.forceToggleOpenAll);
        this.prepareTabledata();
    }
    // =============== приведение СЭЭ в соответствие с ППР ============
   
    private prepareTabledata() {
        this.hierarchyData = [];
        this.isAllOpen = false;
        if (this.data.driData && this.data.peeData && this.data.driData.length > 0) {
            const peeData = JSON.parse(JSON.stringify(this.data.peeData));
            const driData = JSON.parse(JSON.stringify(this.data.driData));
            let peeRecordBase = new BrbpPee();
            driData.forEach((dri: any) => {
                if (peeData.findIndex((item: any) => {
                    return this.comparePrjOrBip(item, dri, this.data, this.curBpState);
                }
                ) === -1) {
                    peeRecordBase = {
                        id: Date.now() + Math.floor(Math.random() * 998) + 1,
                        region: dri.region,
                        bip: dri.bip ? dri.bip : undefined,
                        project: dri.project ? dri.project : undefined,
                        expendReportYear: undefined,
                        expendCorrectPlan: undefined,
                        expendPlanPeriod1: undefined,
                        expendPlanPeriod2: undefined,
                        expendPlanPeriod3: undefined
                    };
                    peeData.push(peeRecordBase);
                }
            });
            peeData.forEach((pee: any, i: number) => {
                const rowIndex = driData.findIndex((item: any) => {
                    return this.comparePrjOrBip(item, pee, this.data, this.curBpState);
                }

                );
                if (rowIndex === -1) {
                    peeData.splice(i, 1);
                }
            });

            if (peeData.length > 0) {
                this.data.peeData = peeData;
            }
            if (peeData.length > 0) {
                this.createHierarchyTable(peeData);
            }
        }
    }

    private hierarchyData: any[] = [];
    private unicRegions: any[] = [];
    private createHierarchyTable(peeData: any[]) {
        const newPeeData = this.addBibObjectIntoTable(JSON.parse(JSON.stringify(peeData)));
        this.unicRegions = [];
        newPeeData.forEach((pee: any) => {
            if (this.unicRegions.indexOf(pee.region) === -1) {
                this.unicRegions.push(pee.region);
            }
            pee.type = 2;
            pee.collapsed = this.ifRegion;
            pee.open = false;
        });
        if (!this.curBpState) {
            this.unicRegions.forEach((region: string) => {
                newPeeData.push({ region, type: 1, collapsed: false, open: false });
            });
        }

        const sortByBipOrProject = (a: any, b: any) => {
            let aa = a.project;
            let bb = b.project;
            if (!this.curBpState) {
                aa = a.bip;
                bb = b.bip;
            }
            let prj = 1;
            if (!!aa && !!bb) {
                prj = (bb.id < aa.id ? 1 : 0) - (aa.id < bb.id ? 1 : 0);
            }
            if (!aa && !!bb) {
                prj = 0;
            }
            if (!!aa && !bb) {
                prj = -1;
            }
            if (!aa && !!bb) {
                prj = 1;
            }
            return prj;
        };
        newPeeData.sort((a: any, b: any) => {
            let res = 0;
            const prj = sortByBipOrProject(a, b);

            let reg = 1;
            if (a.region && b.region) {
                reg = (parseInt(b.region) < parseInt(a.region) ? 1 : 0) - (parseInt(a.region) < parseInt(b.region) ? 1 : 0);
            }
            if (!a.region && !b.region) {
                reg = 0;
            }
            if (!a.region && b.region) {
                reg = 1;
            }
            if (a.region && !b.region) {
                reg = -1;
            }
            if (!this.curBpState) {
                res = (
                    reg
                    || (b.type < a.type ? 1 : 0) - (a.type < b.type ? 1 : 0)
                    || prj
                );
            } else {
                res = ((b.type < a.type ? 1 : 0) - (a.type < b.type ? 1 : 0)|| prj);
            }
            return res;
        });
            
        // adding row number
        let l1 = 0;
        let l2 = 0;
        newPeeData.forEach((item: any) => {
            if (item.type === 1) {
                ++l1;
                if (this.ifRegion) {
                    l2 = 0;
                    item.rowNumber = String(l1);
                }
                item.id = l1 - 10000;
            } else {
                ++l2;
                item.rowNumber = this.ifRegion ? `${l1}.${l2}` : String(l2);
            }
        });
        this.setRoundingRule(newPeeData)
        this.hierarchyData = newPeeData;
        
    }

    private get totals() {
        const totals: any = {
            reportYear: 0,
            correctPlan: 0,
            planPeriod1: 0,
            planPeriod2: 0,
            planPeriod3: 0
        };
        if (this.data.peeData.length > 0) {
            this.data.peeData.forEach((rec: any) => {
                if (rec.expendReportYear) {
                    totals.reportYear += this.roundNumber(rec.expendReportYear, this.numberOfDecimalPlacesProp);
                }
                if (rec.expendCorrectPlan) {
                    totals.correctPlan += this.roundNumber(rec.expendCorrectPlan, this.numberOfDecimalPlacesProp);
                }
                if (rec.expendPlanPeriod1) {
                    totals.planPeriod1 += this.roundNumber(rec.expendPlanPeriod1, this.numberOfDecimalPlacesProp);
                }
                if (rec.expendPlanPeriod2) {
                    totals.planPeriod2 += this.roundNumber(rec.expendPlanPeriod2, this.numberOfDecimalPlacesProp);
                }
                if (rec.expendPlanPeriod3) {
                    totals.planPeriod3 += this.roundNumber(rec.expendPlanPeriod3, this.numberOfDecimalPlacesProp);
                }
            });
        }
        return totals;
    }
    private get totalsByRegion() {
    // подсчет промежуточных итогов для регионов
        const totalsByRegion: {[key: string]: any} = {}
        if (this.unicRegions.length > 0 && this.hierarchyData.length > 0) {
            this.unicRegions.forEach((reg: string) => {
                const currRegionData = this.data.peeData.filter((item: any) => item.hasOwnProperty('region') && item.region === reg);
                const currRegionTotals = {
                    reportYear: 0,
                    correctPlan: 0,
                    planPeriod1: 0,
                    planPeriod2: 0,
                    planPeriod3: 0
                };
                
                currRegionData.forEach((rec: any) => {
                    if (rec.expendReportYear) {
                        currRegionTotals.reportYear += this.roundNumber(rec.expendReportYear, this.numberOfDecimalPlacesProp);
                    }
                    if (rec.expendCorrectPlan) {
                        currRegionTotals.correctPlan += this.roundNumber(rec.expendCorrectPlan, this.numberOfDecimalPlacesProp);
                    }
                    if (rec.expendPlanPeriod1) {
                        currRegionTotals.planPeriod1 += this.roundNumber(rec.expendPlanPeriod1, this.numberOfDecimalPlacesProp);
                    }
                    if (rec.expendPlanPeriod2) {
                        currRegionTotals.planPeriod2 += this.roundNumber(rec.expendPlanPeriod2, this.numberOfDecimalPlacesProp);
                    }
                    if (rec.expendPlanPeriod3) {
                        currRegionTotals.planPeriod3 += this.roundNumber(rec.expendPlanPeriod3, this.numberOfDecimalPlacesProp);
                    }
                });
                totalsByRegion[reg] = currRegionTotals;
            });
        }
        return totalsByRegion;
    }

    public setNameLang(obj: any) {
        if (obj) {
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) { txt = obj.name_ru; }
            return txt;
        }
        return obj;
    }
    private toggleChl(item: any, type: number, arr: any) {
        this.toggleChilds(item, type, arr);
        if (this.isAllOpen) {
            this.isAllOpen = false;
        }
    }
    private forceToggleOpenAll() {
        this.isAllOpen = this.ifRegion;
        this.openAll();
    }
    private isAllOpen = false;
    private openAll() {
        this.setOffset()
        this.isAllOpen = !this.isAllOpen;
        this.hierarchyData.map((item: any) => {
            item.open = this.isAllOpen;
            if (item.type !== 1) {
                item.collapsed = !this.isAllOpen;
            }
            return item;
        });
    }

    // запись данных в таблицу pee при изменении
    private onChangeData(e: any, row: any, column: keyof BrbpPee) {
        if (column !== 'noteRu') { 
            row[column] = e;       
            this.$nextTick(() => {
                e = this.parseVal(e);
                row[column] = e;
                 this.data.peeData.filter((item: any) => item.id === row.id)[0][column] = Number(e);
            });
        } else {
            this.data.peeData.filter((item: any) => item.id === row.id)[0][column] = e;
        }
        this.$emit('compareCostTotals');
        this.$emit('handlingChange');
    }

    // блокировка разделов в зависимости от решения

    private get blockPee() {
        const codesAllowingEditing = ['2', '3', null, undefined];
        if (this.data.brbp && codesAllowingEditing.includes(this.data.brbp.conclPeeCode)) return null;
        return 'budgetprogramdisabled';
    }

    private parseVal(value: string) {
        return this.roundNumber(parseFloat(value.replace(",", ".").replace(/\s/g, '')), this.numberOfDecimalPlacesProp);
    }

    private setRoundingRule(newPeeData: any) {
        newPeeData.forEach((row: any) => {
            if (row.type === 2) {
                this.$set(row, 'expendReportYear', this.roundNumber(row.expendReportYear, this.numberOfDecimalPlacesProp));
                this.$set(row, 'expendCorrectPlan', this.roundNumber(row.expendCorrectPlan, this.numberOfDecimalPlacesProp));
                this.$set(row, 'expendPlanPeriod1', this.roundNumber(row.expendPlanPeriod1, this.numberOfDecimalPlacesProp));
                this.$set(row, 'expendPlanPeriod2', this.roundNumber(row.expendPlanPeriod2, this.numberOfDecimalPlacesProp));
                this.$set(row, 'expendPlanPeriod3', this.roundNumber(row.expendPlanPeriod3, this.numberOfDecimalPlacesProp));
            }
        })
    }

}
