












































































































































































































































































































































































import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from 'vue-class-component';
import { BrbpPprWrapper } from "@/modules/budget-request/components/js/brbp-wrapper";
import { BrbpSee } from '@/modules/budget-request/components/js/see';
import HintList from './table-dri-hint-list.vue';
import { findIndex } from '@amcharts/amcharts4/.internal/core/utils/Iterator';
import BudgerRequestMixin from '../mixins/bp-mixin';
import { DependentRowsDeleter, CanDictBeEditChecker } from "@/modules/budget-request/components/js/bp-handler";

@Component({
    name: 'c-table-budget-effect',
    components: {
        'table-dri-hint-list': HintList
    },
    filters: {
        addRanks: function (value: any) {
            if (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }
        }
    }
})
export default class CTableBudgetEffect extends mixins(BudgerRequestMixin) {

    @Prop({
        required: true,
        default: {}
    })
    private filter!: any;

    @Prop({
        type: [],
        required: true
    })
    private data!: BrbpPprWrapper;

    @Prop({
        required: false,
        default: false
    })
    private conclMode!: boolean;

    @Prop({
        required: true,
    })
    private itputState!: string | null;
    
    @Prop({
        required: true,
    })
    private conclState!: string | null;
    
    @Prop({
        required: true
    })
    private ifRegion!: boolean;

    @Prop({
        required: true
    })
    private localName!: Function;

    @Prop({
        required: true
    })
    private conclAttributes1!: any[];

    @Prop({
        required: true
    })
    private curBpState!: boolean;

    @Prop({
        required: true
    })
    private getCellText!: Function;

    @Prop({
        required: true
    })
    private addRowNumber!: Function;

    @Prop({
        required: true
    })
    private scrollIntoVw!: Function;

    @Prop({
        required: true
    })
    private makeToast!: Function;

    @Prop({
        required: true
    })
    private delDictItem!: Function;

    @Prop({
        required: true
    })
    private onEditDictItem!: Function;

    @Prop({
        required: true
    })
    private toggleChilds!: Function;

    @Prop({
        required: true
    })
    private addBibObjectIntoTable!: Function;

    @Prop({
        required: true
    })
    private getUnitName!: Function;

    @Prop({
        required: true
    })
    private saveDictItem!: Function;

    @Prop({
        required: true
    })
    private keyPress!: Function;
     
    @Prop({
        required: true
    })
    private parseVal!: Function;

    @Prop({
        required: true,
        default: []
    })
    private dictSee!: any[];

    @Prop({
        required: true,
        default: []
    })
    private units!: any[];

    @Prop({
        required: true
    })
    private effTopOffset!: string;

    @Prop({
        required: true
    })
    private setOffset!: Function;
    
    @Prop({
        required: true
    })
    private isSeeDataPassedValidation!: any

     @Prop({
        required: true
    })
    private isDriLoading!: boolean

    private created() {
        this.$watch('isDriLoading', () => {if (!this.isDriLoading) this.prepareTabledata()});
        this.$watch('filter.pgr.ppr', this.prepareTabledata, { deep: true });
        this.$watch('ifRegion', this.prepareTabledata)
        this.$watch('effTopOffset', this.setSecondRowOffset);
        this.prepareTabledata();
    }

    private secondRowOffset: string | null = null;
    private setSecondRowOffset() {
        if (this.effTopOffset !== 'auto') {
            this.secondRowOffset = +this.effTopOffset.substring(0, this.effTopOffset.length - 3) + 46 + 'px';
        }
    }
    private prepareTabledata() {
        this.hierarchyData = [];
        this.isAllOpen = false;
        if (this.data.driData && this.data.seeData && this.data.driData.length > 0) {
            let seeData = JSON.parse(JSON.stringify(this.data.seeData));
            const driData = JSON.parse(JSON.stringify(this.data.driData));
            let seeRecordBase = new BrbpSee();
            driData.forEach((dri: any) => {
                if (seeData.findIndex((item: any) => {
                    return this.comparePrjOrBip(item, dri, this.data, this.curBpState);
                }
                ) === -1) {
                    seeRecordBase = {
                        id: Date.now() + Math.floor(Math.random() * 998) + 1,
                        region: dri.region,
                        bip: dri.bip ? dri.bip : undefined,
                        bipObj: dri.bipObj ? dri.bipObj : undefined,
                        project: dri.project ? dri.project : undefined,
                        see: undefined,
                        effReportYear: undefined,
                        effCorrectPlan: undefined,
                        effPlanPeriod1: undefined,
                        effPlanPeriod2: undefined,
                        effPlanPeriod3: undefined
                    };
                    seeData.push(seeRecordBase);
                }
            });
            const newSeeData: any = [];
            seeData.forEach((see: any, i: number) => {
                const rowIndex = driData.findIndex((item: any) => {
                    return this.comparePrjOrBip(item, see, this.data, this.curBpState);
                });
                if (rowIndex !== -1) {

                    newSeeData.push(see);
                }
            });

            seeData = newSeeData;
            if (seeData.length > 0) {
                this.data.seeData = seeData;
                this.hierarchyTablePrepare(seeData);
            }
            this.prepareSeeData();
        }
    }

    private hierarchyData: any[] = [];
    private isAllOpen = false;
    private hierarchyTablePrepare(seeData: any[]) {
        this.isAllOpen = false;
        this.hierarchyData.splice(0);
        const seeProjects: any[] = [];
        const seeRegions: any[] = [];
        const seeRecords: any[] = [];
        const newSeeData = this.addBibObjectIntoTable(JSON.parse(JSON.stringify(seeData)));
        
        const pushRecord = (recType: string, id: number, region: string, project: any, type: number) => {
            const data = {
                id,
                region,
                project,
                type,
                open: false,
                collapsed: this.ifRegion ? type === 2 : false
            };
            if (recType === 'project') {
                seeProjects.push(data);
            }
            if (recType === 'region') {
                seeRegions.push(data);
            }
        };

        newSeeData.forEach((see: any, i: number) => {
            for (const field of ['effPlanPeriod1', 'effPlanPeriod2', 'effPlanPeriod3']) {
                see[field] = see[field] ? see[field] : 0;
            }
            see.see = see.see ? see.see : null;


            seeRecords.push({
                ...see,
                type: 3,
                open: false,
                collapsed: true,
            });

            const ifSeeRegionsHasDataAndHasRegions = seeRegions.length > 0 && this.ifRegion;
            if (ifSeeRegionsHasDataAndHasRegions) {
                const isDouble = seeRegions.findIndex((item: any) => item.region === see.region) !== -1;
                if (!isDouble) {
                    pushRecord('region', i - 10000000, see.region, { id: i - 10000 }, 1);
                }
            } else {
                pushRecord('region', i - 10000000, see.region, { id: i - 10000 }, 1);
            }

            if (seeProjects.length > 0) {
                const isDouble = seeProjects.findIndex((item: any) => {
                    if (item.region && see.region) {
                        const areRegionAndProjectEqual = item.region === see.region && item.project && see[this.projectFieldName] && item.project.id === see[this.projectFieldName].id;
                        return areRegionAndProjectEqual;
                    } else {
                        const areProjectsEqual = item.project && see[this.projectFieldName] && item.project.id === see[this.projectFieldName].id;
                        return areProjectsEqual
                    }
                }) !== -1;
                if (!isDouble) {
                    pushRecord('project', i - 10000, see.region, see[this.projectFieldName], 2);
                }
            } else {
                pushRecord('project', i - 10000, see.region, see[this.projectFieldName], 2);
            }
        });

        this.hierarchyData = [...seeRegions, ...seeProjects, ...seeRecords]
        this.hierarchyData.sort((a: any, b: any) => {
                // Сначала сортировка по полю "region"
                 if (a.region && b.region) {
                    if (a.region < b.region) return -1;
                    if (a.region > b.region) return 1;
                 }
                // Затем сортировка по полю "project.id"
                const a_projectFieldName = a.type === 3 ? this.projectFieldName : 'project';
                const b_projectFieldName = b.type === 3 ? this.projectFieldName : 'project';
                if (a[a_projectFieldName] && b[b_projectFieldName]) {
                    if (a[a_projectFieldName].id < b[b_projectFieldName].id) return -1;
                    if (a[a_projectFieldName].id > b[b_projectFieldName].id) return 1;
                }

                // И, наконец, сортировка по полю "type"
                return a.type - b.type;
            // return res;
        });

        this.addRowNumber(this.hierarchyData);
    }

    public setNameLang(obj: any) {
        if (obj) {
            let txt = obj['name_' + this.$i18n.locale];
            if (txt === undefined) { txt = obj.name_ru; }
            return txt;
        }
        return obj;
    }

    private openAll() {
        this.setOffset();
        this.isAllOpen = !this.isAllOpen;
        if (!this.isAllOpen) {
            this.hierarchyData.forEach((row: any) => {
                if (row.type === 1 && this.ifRegion) {
                    row.collapsed = false;
                    row.open = false;
                } else if (row.type === 2 && !this.ifRegion) {
                    row.collapsed = false;
                    row.open = false;
                } else {
                    row.collapsed = true;
                    row.open = false;
                }
            });
        } else {
            this.hierarchyData.forEach((row: any) => {
                row.collapsed = false;
                row.open = true;
            });
        }
    }

    private searchLine = '';
    private selectedItem: any | null = null;
    private modalAppendDictItem: any = { show: false };
    private modalDataIsSavable = false;
    private modalAppendDictItemUnit: any | null = null;

    private onOpenModal(dt: any, type: string) {
        this.searchLine = '';
        this.editedRow = dt;
        this.selectedItem = dt[type];
        this.modalAppendDictItem.editCol = type;
        this.scrollIntoVw(this.filteredSee, this.selectedItem, 'cardmodalsee');
    }

    // ============ очистка данных поиска в модальном окне ======
    private onResetModal() {
        this.searchLine = '';
        this.onSearch();
    }

    private filteredSee: any[] = [];
    private prepareSeeData() {
        this.filteredSee.length = 0;
        this.dictSee.forEach((item: any) => {
            this.filteredSee.push({ ...item, filtered: false, value: item });
        });
        this.filteredSee.sort((a: any, b: any) => b.id - a.id);
    }

    private onSearch() {
        const fieldName = this.$i18n.locale === 'en' ? 'name_ru' : 'name_' + this.$i18n.locale;
        this.filteredSee = this.filteredSee.map((item: any) => {
            if (item[fieldName].toLowerCase().indexOf(this.searchLine.toLowerCase()) === -1) {
                return { ...item, filtered: true };
            }
            return { ...item, filtered: false };
        });
    }

    private onChangeRecord(value: string) {
        this.$nextTick(() => {
            this.$set(this, 'modalDataIsSavable', this.modalAppendDictItem.name_ru.length > 0 && !!this.modalAppendDictItemUnit);
        });
    }
    // добавление в справочник 
    private async appendDictItem() {
        if (!this.nameValidation(this.modalAppendDictItem.name_kk, this.modalAppendDictItem.name_ru)) {
            return;
        }

        this.modalAppendDictItem.show = false;

        const savedDictItem = {
            name_kk: this.modalAppendDictItem.name_kk.trim(),
            name_ru: this.modalAppendDictItem.name_ru.trim(),
            unit: this.modalAppendDictItemUnit.code
        };
        const dict = 'bp_see';
        const response = await this.saveDictItem(dict, savedDictItem);
        if (response.status === 200) {
            const newDictItem = await response.json();
            this.searchLine = '';
            if (newDictItem !== null) {
                this.dictSee.push(newDictItem);
                this.selectedItem = newDictItem;
                this.prepareSeeData();
            }
            this.modalAppendDictItem.show = false;
            this.makeToast('success', 'Добавление', 'Запись успешно добавлена');
            this.resetHintsList();
        } else {
            this.makeToast('warning', 'Ошибка сохранения нового элемента', `${response.status} - ${response.statusText}`);
        }
    }

    // ======== поиск похожих наименований =======
    private relativeItemsListRu: any[] = [];
    private relativeItemsListKk: any[] = [];
    private whichHintShow: null | string = null;
    private resetHintsList() {
        this.relativeItemsListRu.length = 0;
        this.relativeItemsListKk.length = 0;
        this.whichHintShow = null;
    }

    // проверка существования записи в справочнике
    private nameValidation(nameKk: string, nameRu: string) {
        if (!this.modalAppendDictItemUnit) {
            this.makeToast('danger', 'Ошибка сохранения', 'Не выбрана единица измерения');
            return false;
        }
        nameKk = nameKk.trim();
        nameRu = nameRu.trim();
        if (nameRu) {
            const ruIndex = this.dictSee.findIndex((item: any) => item.name_ru.toLowerCase() === nameRu.toLowerCase());
            const kkIndex = nameKk ? this.dictSee.findIndex((item: any) => item.name_kk === nameKk) : -1;
            if (ruIndex !== -1 || kkIndex !== -1) {
                this.makeToast('danger', 'Ошибка сохранения', `Запись с таким наименованием на русском ${kkIndex !== -1 ? 'и казахском языках' : 'языке'} уже существует`);
                return false;
            }
        } else {
            this.makeToast('danger', 'Ошибка сохранения', 'Наименование не должно быть пустым');
            return false;
        }
        return true;
    }

    // добавление эффекта в таблицу
    private onRecordChange(e: any) {
        if (this.areThereDoubles()) {
            e.preventDefault();
            return;
        }

        this.hierarchyData = this.hierarchyData.map((item: any) => {
            if (item.id === this.editedRow.id) {
                if (this.selectedItem) {
                    return { ...item, see: this.selectedItem };
                }
            }
            return item;
        });
        this.data.seeData = this.data.seeData.map((item: any) => {
            if (item.id === this.editedRow.id) {
                if (this.selectedItem) {
                    return { ...item, see: this.selectedItem };
                }
            }
            return item;
        });
        this.selectedItem = null;
        this.$bvModal.hide('edit-eff-modal');
        this.$emit('handlingChange');
    }

    private editedRow: any | null = null;

    // поиск дублей в таблице 
    private areThereDoubles() {
        let flag = false;
        this.hierarchyData.forEach((see: any) => {
            if (see.id !== this.editedRow.id) {
                if (see.see
                    && see.see.id
                    && this.selectedItem
                    && this.selectedItem.id
                    && see.see.id === this.selectedItem.id) {
                    if (see[this.projectFieldName]
                        && see[this.projectFieldName].id
                        && this.editedRow[this.projectFieldName]
                        && this.editedRow[this.projectFieldName].id
                        && see[this.projectFieldName].id === this.editedRow[this.projectFieldName].id) {
                        if (see.region && this.editedRow.region && see.region === this.editedRow.region) {
                            flag = true;
                        }
                        if (!see.region && !this.editedRow.region) {
                            flag = true;
                        }

                    }
                }
            }
        });

        if (flag) {
            
            this.selectedItem = null;
            this.makeToast('danger', 'Ошибка', 'Комбинация Город/Район/МСУ + Проект/Мероприятие + Эффект уже существует');
        }
        return flag;
    }

    // добавление новой записи
    private addRow(ind?: number, prevRow?: any) {
        const isIndexExist = ind === 0 || !!ind;
        if (isIndexExist) {
            const isDataValid = this.validateDataBeforeAddRow();
            if (!isDataValid) return;
        }
        
        const newId = Date.now();
        const newRow = {
            id: newId,
            project: prevRow.project,
            region: prevRow.region,
            effPlanPeriod1: null,
            effPlanPeriod2: null, 
            effPlanPeriod3: null,
            type: 3,
            open: true,
            collapsed: false,
            bipObj: prevRow.bipObj,
            bip: prevRow.bip
        };

        if (ind === 0 || ind) {
            const newInd = this.hierarchyData.findIndex((item: any) => item.id === prevRow.id);
            this.hierarchyData.splice(newInd + 1, 0, newRow);
        } else {
            this.hierarchyData.push(newRow);
        }
        this.addRowNumber(this.hierarchyData);
        if (this.data && this.data.seeData) {
            const newBrbpSee = new BrbpSee();
            newBrbpSee.id = newId;
            newBrbpSee.region = prevRow.region;
            if (this.isBipProgram) {
                this.$set(newBrbpSee, 'bipObj', prevRow.bipObj);
                this.$set(newBrbpSee, 'bip', prevRow.bip);
            } else {
                this.$set(newBrbpSee, 'project', prevRow.project);
            }
            
            if (ind === 0 || ind) {
                this.data.seeData.splice(ind + 1, 0, newBrbpSee);
            } else {
                this.data.seeData = [newBrbpSee];
            }
        }
    }

    private validateDataBeforeAddRow(): boolean {
        let isDataValid = true;
        isDataValid = this.isSeeDataPassedValidation(this.data, isDataValid, false); // Предположим, что это асинхронная операция
        if (!isDataValid) {
            this.makeToast('warning', 'Ошибка', 'Необходимо заполнить обязательные поля');
        }
        return isDataValid
    }

    // удаление строки таблицы
    private removeRow(ind: number, row: any) {
        if (this.data && this.data.seeData) {
            const index = this.data.seeData.findIndex((item: any) => item.id === row.id);
            this.data.seeData.splice(index, 1);

            const drd = new DependentRowsDeleter(this.hierarchyData, this.ifRegion, this.filter);
            if (!drd.checkIfOtherChildrenExist(row)) {
                this.addRow(ind, row);
            }
            drd.deleteCurrentItem(row);
        }
        if (this.data.seeData.isEmpty) {
            this.hierarchyData.length = 0;
        }
        this.addRowNumber(this.hierarchyData);
    }

    // удаление из справочника программы или показателя
    private async onDeleteItem() {
        const dict = 'bp_see';
        const recordId = this.selectedItem.id;

        const response = await this.delDictItem(dict, recordId);
        if (response.status === 200) {
            const delId = this.dictSee.findIndex((elem: any) => elem.id === recordId);
            this.dictSee.splice(delId, 1);
            this.prepareSeeData();
            this.selectedItem = null;
        } else if (response.status === 409) {
            this.makeToast('warning', 'Ошибка удаления', 'Выбранный Эффект невозможно удалить, т.к. он используется в описаниях других мероприятий');
        }
    }

    // очистка инпутов в окне добавления записи в справочник
    private resetFields() {
        this.modalAppendDictItem.name_kk = '';
        this.modalAppendDictItem.name_ru = '';
        this.modalAppendDictItemUnit = null;
        this.modalDataIsSavable = false;
    }

    // ============= заполнение полей в модалке при редактировании
    private editedNameRu = '';
    private editedNameKk = '';
    private fillFields() {
        this.editedNameKk = this.selectedItem.name_kk;
        this.editedNameRu = this.selectedItem.name_ru;
        this.modalAppendDictItemUnit = this.units.filter((unit: any) => unit.code === this.selectedItem.unit)[0];
        this.modalDataIsSavable = false;
    }

    // поиск существующих значений в справочнике программ или индикаторов
    private popUpListGenerator(e: any, lang: string, action: string) {
        if (e.key === "Enter") {
            e.preventDefault();
        }
        const allItemsList = this.filteredSee;
        const atrName = 'name_' + lang;
        let substrText = '';
        if (action === 'delete') {
            substrText = this.modalAppendDictItem[atrName];
        }
        if (action === 'edit') {
            if (lang === 'ru') {
                substrText = this.editedNameRu;
            }
            if (lang === 'kk') {
                substrText = this.editedNameKk;
            }
        }

        const arrItems: any[] = [];
        for (const item of allItemsList) {
            const strText = item[atrName];
            if (substrText && strText && strText.toLowerCase().indexOf(substrText.toLowerCase()) === 0) {
                arrItems.push(strText);
            }
            if (arrItems.length === 10) {
                break;
            }
        }
        if (lang === 'ru') {
            this.relativeItemsListRu = arrItems;
        }
        if (lang === 'kk') {
            this.relativeItemsListKk = arrItems;
        }
    }

    // ============= редактирование
    private async onRecordEdit(e: any) {
        e.preventDefault();
        if (!this.nameValidation(this.editedNameKk, this.editedNameRu)) {
            return;
        }
        const dictItem = { ...this.selectedItem,
            name_ru: this.editedNameRu.trim(),
            name_kk: this.editedNameKk.trim(),
            unit: this.modalAppendDictItemUnit ? this.modalAppendDictItemUnit.code : null };
        const dict = 'bp_see';

        const response = await this.onEditDictItem(dict, dictItem);
        if (response.status === 200) {
            const newDictItem = await response.json();
            if (newDictItem !== null) {
                const itemIndex = this.dictSee.findIndex((item: any) => item.id === newDictItem.id);
                this.dictSee.splice(itemIndex, 1, newDictItem);
                this.selectedItem = newDictItem;
                this.prepareSeeData();
                this.$root.$emit('bv::hide::modal', 'modal-edit-eff-item');
                this.modalAppendDictItem.show = false;
                this.resetHintsList();
            }
        }
    }

    // запись данных в таблицу pee при изменении
    private onChangeData(e: any, row: any, column: keyof BrbpSee) {
        if (column !== 'noteRu') {
            e = this.parseVal(e);
            row[column] = e;
        }
        this.data.seeData.filter((item: any) => item.id === row.id)[0][column] = column !== 'noteRu' ? Number(e) : e;
        this.$emit('handlingChange');
    }

    // блокировка разделов в зависимости от решения
    private get blockSee() {
        const codesAllowingEditing = ['2', '3', null, undefined];
        if (this.data.brbp && codesAllowingEditing.includes(this.data.brbp.conclSeeCode)) return null;
        return 'budgetprogramdisabled';
    }

    private onModaldbClick(evnt: any) {
        if (evnt.target.classList.contains("custom-control-label") || evnt.target.parentElement.classList.contains("custom-control-label")) {
            this.onRecordChange(evnt);
        }
    } // создание программы по двойному щелчку

    private moneyFields = ['effPlanPeriod1', 'effPlanPeriod2', 'effPlanPeriod3'];

    private isSeeFieldNotValid(row: any, fieldName: string): boolean {
        let moneySum = 0;
        this.moneyFields.forEach((moneyFieldName: string) => {
            moneySum += row[moneyFieldName] ? Number(row[moneyFieldName]) : 0;
        })
        
        const isRowEmpty = !row.see && moneySum === 0;
        if (isRowEmpty) return false;

        if (['see'].includes(fieldName)) {
            return !row[fieldName];
        } else {
            return moneySum === 0;
        }
    }

    private canDictBeEditChecker = new CanDictBeEditChecker(this);
    private isSearchingExistedDictItems = false;
    private async onDelEffectClick() {
        this.isSearchingExistedDictItems = true;
        const isEffectExist = await this.searchingExistedEffects();
        if (isEffectExist) {
            this.makeToast('warning', 'Ошибка', `Эффект не может быть удален, т.к. он уже используется в ПЭЭ`);
        } else this.$bvModal.show('del-eff-modal');
        this.isSearchingExistedDictItems = false;
    }

    private async onEditEffectClick() {
        this.isSearchingExistedDictItems = true;
        const isEffectExist = await this.searchingExistedEffects();
        if (isEffectExist) {
            this.makeToast('warning', 'Ошибка', `Эффект не может быть изменен, т.к. он уже используется в ПЭЭ`);
        } else {
            this.fillFields();
            this.$bvModal.show('modal-edit-eff-item');
        }
        this.isSearchingExistedDictItems = false;
    }

    private async searchingExistedEffects() {
        await this.canDictBeEditChecker.getExistingSeeEffect(this.selectedItem.id);
        return this.canDictBeEditChecker.isEffectExist;
    }

    private get isBipProgram(): boolean {
        return this.filter.bpState === 1;
    }

    private get projectFieldName(): string {
        return this.isBipProgram ? 'bipObj' : 'project';
    }
}
