




































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { BrbpDri } from '@/modules/budget-request/components/js/dri';
import { BrbpPprWrapper } from "@/modules/budget-request/components/js/brbp-wrapper";
import HintList from './table-dri-hint-list.vue';
import { BipProjectDataClass, ProjectStatusList } from '@/modules/budget/bip/bip-types';
import CTableBudgetEffect from '@/modules/budget-request/components/table-budget-effect.vue';
import CTableBudgetCosts from '@/modules/budget-request/components/table-budget-costs.vue';
import { BipStatus, BipBase } from '@/modules/budget-request/components/js/bpInterfaces';
import { DependentRowsDeleter, CanDictBeEditChecker } from "@/modules/budget-request/components/js/bp-handler";

Vue.use(BootstrapVueIcons);

@Component({
    name: 'c-table-dri-edit',
    components: { 
        'table-dri-hint-list': HintList,
        'c-table-budget-effect': CTableBudgetEffect,
        'c-table-budget-costs': CTableBudgetCosts },
    filters: {
        addRanks: function (value: any) {
            if (value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }
        }
    }
})

export default class CTableDriEditExpend extends Vue {
    @Prop({
        type: Object,
        required: true
    })
    private data!: BrbpPprWrapper;

    @Prop({
        required: true,
        default: {}
    })
    private filter: any;

    @Prop({
        required: true,
        default: null
    })
    private curVariant!: string | null;

    @Prop({
        required: true,
        default: []
    })
    private finalResults!: any[];

    @Prop({
        required: true,
        default: []
    })
    private dictSee!: any[];

    @Prop({
        required: true,
        type: Boolean
    })
    private ifFrsShouldBeCleared!: boolean;

    @Prop({
        required: false,
        default: false
    })
    private conclMode!: boolean;

    @Prop({
        required: true,
    })
    private itputState!: string | null;

    @Prop({
        required: true,
    })
    private conclState!: string | null;

    @Prop({
        required: true,
        default: 'ppr'
    })
    private selectedTable!: string;

    @Prop({
        required: false,
        default: false
    })
    private curBpState!: boolean;
    @Prop({
        required: false,
    })
    private regionCityTrigger!: boolean;

    @Prop({
        required: true
    })
    private driTopOffset!: string;

    @Prop({
        required: true
    })
    private costTopOffset!: string;

    @Prop({
        required: true
    })
    private effTopOffset!: string;

    @Prop({
        required: true
    })
    private setOffset!: Function;

    @Prop({
        required: true
    })
    private localName!: Function;

    @Prop({
        required: true
    })
    private conclAttributes1!: any[];

    @Prop({
        required: true
    })
    private compareCostTotals!: Function;

    @Prop({
        required: true
    })
    private numberOfDecimalPlacesProp!: number

    @Prop({
        required: true
    })
    private isDriDataPassedValidation!: any

    @Prop({
        required: true
    })
    private isSeeDataPassedValidation!: any

    private get placeholder() {
        return this.modalAppendDictItem.editCol === 'project' ? 'Найти проект' : 'Найти показатель ППР';
    }

    private handlingChange() {
        this.$emit('onDataChanged');
    }
    private addFR(e: any, id: number) {
        const newDri = e.join(',');
        for (const [i, dri] of this.data.driData.entries()) {
            if (dri.id === id) {
                this.data.driData[i].finalResult = newDri.length > 0 ? newDri : null;
                break;
            }
        }
        for (const [i, dri] of this.hierarchDriData.entries()) {
            if (dri.id === id) {
                this.hierarchDriData[i].finalResult = newDri.length > 0 ? newDri : null;
                break;
            }
        }
        this.$emit('onDataChanged');
    }

    private get modalOkDisabled() {
        if (!this.modalAppendDictItem) {
            return false;
        }
        if (!this.modalAppendDictItem.name_ru) {
            return false;
        }
        if (this.modalAppendDictItem.name_ru.trim().length < 1) {
            return false;
        }
        return true;
    }

    public regions: any = [];

    private projects: any = [];

    private bipProjects: any = [];

    private dris: any = [];

    private units: any = [];

    private defaultNameProp = {
        kk: 'name_kk',
        ru: 'name_ru'
    };

    private tableCols: any[] = [
        'index',
        {
            key: 'region',
            label: 'Город/Район/МСУ',
            kind: 'dim',
            dimKey: 'code',
            nameProp: this.defaultNameProp,
            items: 'regions'
        },
        {
            key: 'project',
            label: 'Проект',
            kind: 'dim',
            dimKey: 'id',
            nameProp: this.defaultNameProp,
            items: 'projects'
        },
        {
            key: 'indicator',
            label: 'ППР',
            thStyle: 'min-width: 20em',
            kind: 'dim',
            dimKey: 'id',
            nameProp: this.defaultNameProp,
            items: 'dris'
        },
        {
            key: 'unit',
            label: 'Ед.измерения',
            kind: 'dim'
        },
        {
            key: 'finalResult',
            label: 'Конечный результат'
        },
        {
            key: 'reportYear',
            label: ' '
        },
        {
            key: 'correctPlan',
            label: ''
        },
        {
            key: 'planPeriod1',
            label: ''
        },
        {
            key: 'planPeriod2',
            label: ''
        },
        {
            key: 'planPeriod3',
            label: ''
        },
        {
            key: 'action',
            label: ''
        }
    ];

    private getFinalResultText(frId: string) {
        const currFR = this.finalResults.find((fr: any) => fr.code === frId);
        if (currFR) {
            return currFR.name_ru;
        }
        return null;
    }
    private getCellText(row: any, colName: string): string | null {
        if (!this.tableCols) {
            return null;
        }
        const col = this.tableCols.find((c: any) => c.key === colName);
        const v = row[col.key];
        if (!v && v !== 0) {
            return null;
        }
        if (col.dimKey === 'id') {
            return v[col.nameProp.ru];
        }
        const that: any = this;
        const items = that[col.items];
        const dimItem = items.find((item: any) => item[col.dimKey] === v);
        return (dimItem) ? dimItem[col.nameProp.ru] : '';
    }

    private editRowIndexTable = this.conclMode ? -1 : 0;
    private editRowIndexDri = this.conclMode ? -1 : 0;


    private modalAppendDictItem: any = { show: false };

    private getUnitName(dataRec: any): string | null {
        let unit: any = null;
        if (dataRec.indicator && dataRec.indicator.unit) {
            unit = (this.units.find((u: any) => u.code === dataRec.indicator.unit));
        }
        if (dataRec.see && dataRec.see.unit) {
            unit = (this.units.find((u: any) => u.code === dataRec.see.unit));
        }
        if (unit) {
            return this.$i18n.locale === 'kk' ? unit.name_kk : unit.name_ru;
        }
        return null;
    }

    private created() {
        this.watchOnDataEdit();
        this.loadRegions();
        this.loadBibOrProject();
        this.loadDris();
        this.loadUnits();
    }

    private watchOnDataEdit() {
        const that = this;
        this.$watch('searchLine', function () { if (!that.searchLine) { that.onResetModal(); } });
        this.$watch('filter.region', that.loadRegions);
        this.$watch('filter.pgr.ppr', function () {if (this.isBipProgram) {that.filterBipProjects() } });
        this.$watch('filter.pgr', that.hierarchTablePrepare, { deep: true });
        this.$watch('filter.curBpGoal', that.hierarchTablePrepare);
        this.$watch('regionCityTrigger', that.hierarchTablePrepare);
        this.$watch('ifFrsShouldBeCleared', that.hierarchTablePrepare);
        this.$watch('projects', that.hierarchTablePrepare);
        this.$watch('bipStatuses', that.setBipStatuses);
    }

    public updateTables() {
        this.hierarchTablePrepare()
    }

    private isDriLoading: boolean = false;
    // scroll to selected item into modal
    private scrollIntoVw(arr: any, selectedItem: any, type: string) {
        setTimeout(() => {
            const newArr = [...arr.filter((item: any) => item.filtered === false)];
            if (selectedItem) {
                const pos = newArr.findIndex((item: any) => item.id === selectedItem.id);
                const element = (this.$refs[type] as HTMLDivElement);
                if (element && element.firstChild && element.firstChild.childNodes[pos]) {
                    const node = element.firstChild.childNodes[pos] as Element;
                    node.scrollIntoView({ block: 'center' });
                }
            }
        }, 1000);
    }


    private onOpenModal(dt: any, type: string, tableIndex: number) {  
        this.setIndexes(tableIndex, dt)      
        if (this.editRowIndexTable > -1) {
            this.modalAppendDictItem.prevItem = this.hierarchDriData[this.editRowIndexTable][type];
        }
        // this.editRowIndexDri = this.getDriRowId(dt);
        this.searchLine = '';
        this.editedRow = dt;
        this.selectedItem = dt[type];
        this.modalAppendDictItem.editCol = type;
        this.modalAppendDictItem.name_kk = '';
        this.modalAppendDictItem.name_ru = '';
        this.modalAppendDictItemUnit = null;
        this.modalDataIsSavable = false;
        if (type === 'project') {
            this.scrollIntoVw(this.filteredProjects, this.selectedItem, 'cardmodalprj');
        } else {
            this.scrollIntoVw(this.filteredIndicators, this.selectedItem, 'cardmodalind');
        }
        this.getModalAddDictItem();
    }

    private appendDictItem(e: any) {
        e.preventDefault();
        if (this.editRowIndexTable > -1 && (this.modalAppendDictItem.editCol === 'indicator'
            || this.modalAppendDictItem.editCol === 'project')) {
            this.findOrCreateNewDictItem();
        }
    }
    private modalAddDictItemTitle: string = '';
    private getModalAddDictItem() {
        switch (this.modalAppendDictItem.editCol) {
            case 'project':
                this.modalAddDictItemTitle = 'проект';
                break;
            case 'indicator':
                this.modalAddDictItemTitle = 'ППР';
                break;
            default:
                this.modalAddDictItemTitle = 'элемент справочника';
                break;
        }
    }

    private cancelAppend() {
        if (this.editRowIndexDri > -1) {
            switch (this.modalAppendDictItem.editCol) {
                case 'indicator':
                    this.data.driData[this.editRowIndexDri].indicator = this.modalAppendDictItem.prevItem;
                    break;
                case 'project':
                    this.data.driData[this.editRowIndexDri].project = this.modalAppendDictItem.prevItem;
                    break;
                default:
                    break;
            }
        } else {
            this.modalAppendDictItem.show = false;
        }
        this.resetHintsList();
    }

    private getDriRowId(row: any) {
        return this.data.driData.findIndex((item: any) => item.id === row.id);
    }

    private setIndexes(index: number, row?: any) {
        this.editRowIndexTable = this.conclMode ? -1 : index;
        if (row) {
            this.editRowIndexDri = this.getDriRowId(row);
        } else {
            this.editRowIndexDri = this.editRowIndexTable;
        }
    }

    private regionLevelType = 1;
    private projectLevelType = 2;
    private childLevelType = 3;


    private async addRow(ind?: number, prevRow?: any) {
        const isIndexExist = ind === 0 || !!ind;
        if (isIndexExist) {
            const isDataValid = this.validateDataBeforeAddRow();
            if (!isDataValid) return;
        }
        
        const newId = Date.now();
        const newRow = {
            id: newId,
            project: null,
            region: '',
            finalResult: '',
            type: this.childLevelType,
            open: true,
            collapsed: false
        };

        if (isIndexExist) {
            const newInd = this.hierarchDriData.findIndex((item: any) => item.id === prevRow.id);
            this.hierarchDriData.splice(newInd + 1, 0, newRow);
            // this.editRow(ind! + 1);
        } else {
            this.hierarchDriData.push(newRow);
            // this.editRow(0);
        }
        this.addRowNumber();

        if (this.data && this.data.driData) {
            const newBrbpDri = new BrbpDri();
            newBrbpDri.id = newId;
            newBrbpDri.finalResult = '';
            newBrbpDri.project = null;
            newBrbpDri.region = '';
            if (isIndexExist) {
                const nextInd = ind! + 1;
                this.data.driData.splice(nextInd, 0, newBrbpDri);
                // this.editRow(nextInd);
            } else {
                this.data.driData = [newBrbpDri];
                // this.editRow(0);
            }
        }
    }

    private validateDataBeforeAddRow(): boolean {
        let isDataValid = true;
        isDataValid = this.isDriDataPassedValidation(this.data, isDataValid); 
        
        if (!isDataValid) {
            this.makeToast('warning', 'Ошибка', 'Необходимо заполнить обязательные поля');
        }
        return isDataValid;
    }

    private removeRow(row: any) {
        if (this.data && this.data.driData) {
            const index = this.data.driData.findIndex((item: any) => item.id === row.id);
            this.data.driData.splice(index, 1);
            const drd = new DependentRowsDeleter(this.hierarchDriData, this.ifRegion, this.filter);
            drd.deleteDependentRows(row);
        }
        if (this.data.driData.isEmpty) {
            this.hierarchDriData.splice(0);
        }
        this.addRowNumber();
        this.updateSeeTable();
    }

    private async loadRegions() {
        try {
            if (this.filter.region && this.filter.region.code.slice(-2) === '01') {
                const response: any = await fetch(`/api/budget-programs/budget_regions/sub?region=${this.filter.region.code}`);
                if (response.status === 200) {
                    const regions = await response.json();
                    regions.sort((a: any, b: any) => a.code.localeCompare(b.code));
                    this.regions = regions;
                } else {
                    this.makeToast('danger', 'Ошибка загрузки данных справочника регионов', `${response.status} - ${response.statusText}`);
                }
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки данных справочника регионов', error.toString());
        }
    }

    private loadBibOrProject() {
        this.isDriLoading = true;
        if (this.isBipProgram) {
            this.loadBipProjects();
        } else {
            this.loadProjects();
        }
    }
    private bipProjectsBaseData: any[] = [];
    private async loadBipProjects() {
        if (!this.curVariant) return;
        this.bipProjectsBaseData.splice(0);
        this.bipStatuses.splice(0);

        let response: any;
        try {
            response = await fetch(`/api-py/bip-forms-without-denied/${this.curVariant}`);
            if (response.status === 200) {
                response = await response.json();
                
                this.bipProjectsBaseData = response.map((bip: any) => {
                    if (bip.isDeleted) {                        
                        bip.isDeleted = false;
                    }
                    return bip;
                });
                this.filterBipProjects();
            } else {
                this.makeToast('danger', 'Ошибка загрузки bip-list', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки bip-list', error.toString());
            response = null;
        }
        this.isDriLoading = false;
    }

    private filterBipProjects() {
        this.projects.length = 0;
        const isPprMatch = (bip: any) => {
            let res = false;
            let arr = [];
            if (bip.variant && bip.variant === this.curVariant) {
                arr = bip.newFinace ? bip.newFinace : []
            } else arr = bip.newFinaceRegional ? bip.newFinaceRegional : []
            if (arr && arr.length > 0) {
                arr.forEach((item: any) => {
                    if (item.prg === this.filter.prg.prg && (!this.filter.pgr || item.ppr === this.filter.pgr.ppr)) {
                        res = true;
                    }
                });
            }
            return res;
        };
        const isAbpMatch = (bip: any): boolean => {
            if (bip.variant && bip.variant === this.curVariant) {
                return bip.header.abp && bip.header.abp === this.filter.abp.abp;
            } else {
                return bip.header.regional && bip.header.regional.abp && parseInt(bip.header.regional.abp) === this.filter.abp.abp;
            }
        }
        
        if (this.bipProjectsBaseData.length > 0) {
            const bipList: string[] = []
            const newResArr = this.bipProjectsBaseData.reduce((newArr, bip) => {
                const isBipActual = (bip.header) && (bip.isDeleted === false) && ((bip.header.year === undefined) || (bip.header.year === this.filter.year))
                    && isAbpMatch(bip) && isPprMatch(bip)
                if (isBipActual) {
                    bipList.push(bip.code)
                    const newObj = {
                        id: bip.code,
                        name_kk: bip.nameKk,
                        name_ru: bip.nameRu,
                        name: `${bip.code} - ${bip.nameRu}`
                    }
                    newArr.push(newObj)
                }
                return newArr; 
            }, []);

            this.projects = newResArr
           
            if (bipList.length) this.getBipStatus(bipList);


            this.prepareProjectData(this.projects);
        }
    }
    
    private bipStatuses: BipStatus[] = []
    private async getBipStatus(bipsList: string[]) {
        const data = {
            bipList: bipsList,
            abp: this.filter.abp.abp,
            variant: this.curVariant,
        }
        try {
            const response = await fetch('/api-py/get-bip-status/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(data)
                });
                if (response.status === 200) {
                    this.bipStatuses = await response.json();
                } else {
                    this.makeToast('danger', 'Ошибка загрузки данных справочника "Статусы БИП"', `${response.status} - ${response.statusText}`);
                }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки данных справочника "Статусы БИП"', error.toString());
        }
    }

    private setBipStatuses() {
        if (!this.bipStatuses || this.bipStatuses.length === 0) return;
        this.projects = this.projects.map((bip: any) => {
            const curStatus = this.bipStatuses.find((bs: any) => bs.bip_code === bip.id)
            if (curStatus) bip.name = bip.name + ` - ${curStatus.name_ru}`
            return bip;
        })
        this.prepareProjectData(this.projects);
    }


    private projectStatusList = ProjectStatusList;
    private getStatusName(objectId: number): string {
        for (const pr of this.projectStatusList) {
            if (objectId.toString() === pr.code) {
                return pr.nameRu;
            }
        }
        return objectId.toString();
    }

    private async loadProjects() {
        this.projects.length = 0;
        try {
            const response: any = await fetch('/api/budget-programs/bp_project');
            if (response.status === 200) {
                this.projects = await response.json();
                this.prepareProjectData(this.projects);
            } else {
                this.makeToast('danger', 'Ошибка загрузки данных справочника "Бюджетные программы - проекты"', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки данных справочника "Бюджетные программы - проекты"', error.toString());
        }
        this.isDriLoading = false;
    }
    private filteredProjects: any[] = [];
    private filteredIndicators: any[] = [];
    private prepareProjectData(projects: any) {
        this.filteredProjects.length = 0;
        projects.forEach((item: any) => {
            this.filteredProjects.push({ ...item, filtered: false, value: item });
        });
        this.filteredProjects.sort((a: any, b: any) => b.id - a.id);
    }
    private prepareIndicatorsData(projects: any) {
        this.filteredIndicators.length = 0;
        projects.forEach((item: any) => {
            this.filteredIndicators.push({ ...item, filtered: false, value: item });
        });
        this.filteredIndicators.sort((a: any, b: any) => b.id - a.id);
    }
    // загрузка справочника бюджетных программ для ППР
    private async loadDris() {
        try {
            const response: any = await fetch('/api/budget-programs/bp_dri');
            if (response.status === 200) {
                this.dris = await response.json();
                this.prepareIndicatorsData(this.dris);
            } else {
                this.makeToast('danger', 'Ошибка загрузки данных справочника "Бюджетные программы - ППР"', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки данных справочника "Бюджетные программы - ППР"', error.toString());
        }
    }
    // загрузка справочника единиц измерения для ППР
    private async loadUnits() {
        try {
            const response: any = await fetch('/api/budget-programs/stat_unit');
            if (response.status === 200) {
                this.units = await response.json();
                this.units.sort((a: any, b: any) => {
                    const nameA = a.name_ru.toLowerCase();
                    const nameB = b.name_ru.toLowerCase();
                    if (nameA < nameB) { return -1; }
                    if (nameA > nameB) { return 1; }
                    return 0;
                });
            } else {
                this.makeToast('danger', 'Ошибка загрузки данных справочника единиц измерения', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки данных справочника единиц измерения', error.toString());
        }
    }
    private nameValidation(nameKk: string, nameRu: string) {
        nameKk = nameKk.trim();
        nameRu = nameRu.trim();
        if (nameRu) {
            if (this.modalAppendDictItem.editCol === 'project') {
                const ruIndex = this.projects.findIndex((item: any) => item.name_ru.toLowerCase() === nameRu.toLowerCase());
                const kkIndex = nameKk ? this.projects.findIndex((item: any) => item.name_kk === nameKk) : -1;
                if (ruIndex !== -1 || kkIndex !== -1) {
                    this.makeToast('danger', 'Ошибка сохранения', `Запись с таким наименованием на русском ${kkIndex !== -1 ? 'и казахском языках' : 'языке'} уже существует`);
                    return false;
                }
            }
        } else {
            this.makeToast('danger', 'Ошибка сохранения', 'Наименование не должно быть пустым');
            return false;
        }
        return true;
    }
    // ============= редактирование
    private async onRecordEdit(e: any) {
        e.preventDefault();
        if (!this.nameValidation(this.editedNameKk, this.editedNameRu)) {
            return;
        }
        const dictItem = { ...this.selectedItem,
            name_ru: this.editedNameRu.trim(),
            name_kk: this.editedNameKk.trim(),
            unit: this.modalAppendDictItemUnit ? this.modalAppendDictItemUnit.code : null };
        let dict: string | null = null;
        switch (this.modalAppendDictItem.editCol) {
            case 'indicator':
                dict = 'bp_dri';
                break;
            case 'project':
                dict = 'bp_project';
                break;
            default:
                return;
        }
        const response = await this.onEditDictItem(dict, dictItem);
        if (response.status === 200) {
            const newDictItem = await response.json();
            if (newDictItem !== null) {
                if (this.modalAppendDictItem.editCol === 'indicator') {
                    const itemIndex = this.dris.findIndex((item: any) => item.id === newDictItem.id);
                    this.dris.splice(itemIndex, 1, newDictItem);
                    this.selectedItem = newDictItem;
                    this.prepareIndicatorsData(this.dris);
                    this.data.driData[this.editRowIndexDri].indicator = newDictItem;
                } else if (this.modalAppendDictItem.editCol === 'project') {
                    this.projects = this.projects.map((item: any) => {
                        if (item.id === newDictItem.id) {
                            return newDictItem;
                        }
                        return item;
                    });
                    this.selectedItem = newDictItem;
                    this.prepareProjectData(this.projects);
                    this.data.driData[this.editRowIndexDri].project = newDictItem;
                }
                this.$root.$emit('bv::hide::modal', 'modal-edit-dict-item');
                this.modalAppendDictItem.show = false;
            }
        }
    }

    // ====== запрос на изменение записи в словаре ===========
    private async onEditDictItem(dict: string, dictItem: any) {
        const url = `/api/budget-programs/${dict}`;
        const response: any = await fetch(url, {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: JSON.stringify(dictItem) // body data type must match "Content-Type" header
        });
        await this.onEditDictItemErrMsgGenerator(response, dict);
        return response;
    }

    private async onEditDictItemErrMsgGenerator(response: any, dict: string) {
        if (!response.ok) {
            if (response.status === 400) {
                const result = await response.json();
                let errMsg =  `Ошибка: ${response.status}`;
                if (result.key === "dict.item.in-use") {
                    switch (dict) {
                        case 'bp_dri':
                            errMsg = 'Показатель не может быть изменен, т.к. он уже используется в ППР';
                            break;
                        case 'bp_project':
                            errMsg = 'Проект не может быть изменен, т.к. он уже используется в ППР';
                            break;
                        case 'bp_see':
                            errMsg = 'Эффект не может быть изменен, т.к. он уже используется в ПЭЭ';
                            break;
                        default:
                            break;
                    }
                } 
                this.makeToast('danger', 'Ошибка изменения записи', errMsg);
            }
        } else {
            this.makeToast('success', 'Изменение', 'Запись успешно изменена');
        }
    }

    // ============= заполнение полей в модалке при редактировании
    private editedNameRu = '';
    private editedNameKk = '';
    private fillFields() {
        this.editedNameKk = this.selectedItem.name_kk;
        this.editedNameRu = this.selectedItem.name_ru;
        this.modalAppendDictItemUnit = this.units.filter((unit: any) => unit.code === this.selectedItem.unit)[0];
        this.modalDataIsSavable = false;
    }
    // удаление из справочника программы или показателя
    private async onDeleteItem() {
        let dict: string | null = null;
        let recordId: number;
        switch (this.modalAppendDictItem.editCol) {
            case 'indicator':
                dict = 'bp_dri';
                recordId = this.selectedItem.id;
                break;
            case 'project':
                dict = 'bp_project';
                recordId = this.selectedItem.id;
                break;
            default:
                return;
        }
        const response = await this.delDictItem(dict, recordId);
        if (response.status === 200) {
            if (this.modalAppendDictItem.editCol === 'indicator') {
                const delId = this.dris.findIndex((elem: any) => elem.id === recordId);
                this.dris.splice(delId, 1);
                this.prepareIndicatorsData(this.dris);
                this.selectedItem = null;
            } else if (this.modalAppendDictItem.editCol === 'project') {
                const delId = this.projects.findIndex((elem: any) => elem.id === recordId);
                this.projects.splice(delId, 1);
                this.prepareProjectData(this.projects);
                this.selectedItem = null;
            }
            // this.makeToast('success', 'Удаление', 'Запись успешно удалена');
        } else if (response.status === 409) {
            this.makeToast('warning', 'Ошибка удаления', 'Выбранный Проект/ППР невозможно удалить, т.к. он используется в описаниях других мероприятий');
        } else {
            // this.makeToast('danger', 'Ошибка удаления', `${response.status} - ${response.statusText}`);
        }
    }

    private async delDictItem(dict: string, recordId: number) {
        const url = `/api/budget-programs/${dict}/${recordId}`;
        const response = await fetch(url, {
            method: 'DELETE'
        });
        await this.onDelItemErrMsgGenerator(response, dict);
        
        return response;
    }

    private async onDelItemErrMsgGenerator(response: any, dict: string) {
        if (!response.ok) {
            if (response.status === 400) {
                const result = await response.json();
                let errMsg =  `Ошибка: ${response.status}`;
                if (result.key === "dict.item.in-use") {
                    switch (dict) {
                        case 'bp_dri':
                            errMsg = 'Показатель не может быть удален, т.к. он уже используется в ППР';
                            break;
                        case 'bp_project':
                            errMsg = 'Проект не может быть удален, т.к. он уже используется в ППР';
                            break;
                        case 'bp_see':
                            errMsg = 'Эффект не может быть удален, т.к. он уже используется в ПЭЭ';
                            break;
                        default:
                            break;
                    }
                } 
                this.makeToast('danger', 'Ошибка удаления', errMsg);
            }
        } else {
            this.makeToast('success', 'Удаление', 'Запись успешно удалена');
        }
    }

    // добавление в справочник индикатора или показателя
    private async findOrCreateNewDictItem() {
        if (!this.nameValidation(this.modalAppendDictItem.name_kk, this.modalAppendDictItem.name_ru)) {
            return;
        }

        this.modalAppendDictItem.show = false;

        const savedDictItem = {
            name_kk: this.modalAppendDictItem.name_kk.trim(),
            name_ru: this.modalAppendDictItem.name_ru.trim(),
            unit: this.modalAppendDictItemUnit ? this.modalAppendDictItemUnit.code : null
        };
        let dict: string | null = null;
        let dictArr;
        switch (this.modalAppendDictItem.editCol) {
            case 'indicator':
                dict = 'bp_dri';
                dictArr = this.dris;
                break;
            case 'project':
                dict = 'bp_project';
                dictArr = this.projects;
                break;
            default:
                this.makeToast('error', 'Ошибка сохранения',
                    `Нет реализации сохранения нового элемента для поля ${this.modalAppendDictItem.editCol}`);
                return;
        }
        const dictItem = dictArr.find((d: any) => d.name_ru === savedDictItem.name_ru);
        if (dictItem) {
            switch (this.modalAppendDictItem.editCol) {
                case 'indicator':
                    this.data.driData[this.editRowIndexDri].indicator = dictItem;
                    break;
                case 'project':
                    this.data.driData[this.editRowIndexDri].project = dictItem;
                    break;
            }
            return;
        }
        const response = await this.saveDictItem(dict, savedDictItem);
        if (response.status === 200) {
            const newDictItem = await response.json();
            this.searchLine = '';
            if (newDictItem !== null) {
                if (this.modalAppendDictItem.editCol === 'indicator') {
                    this.dris[this.dris.length] = newDictItem;
                    this.selectedItem = newDictItem;
                    this.prepareIndicatorsData(this.dris);
                } else if (this.modalAppendDictItem.editCol === 'project') {
                    this.projects[this.projects.length] = newDictItem;
                    this.selectedItem = newDictItem;
                    this.prepareProjectData(this.projects);
                }
                this.modalAppendDictItem.show = false;
                this.makeToast('success', 'Добавление', 'Запись успешно добавлена');
                this.resetHintsList();
            }
        } else {
            this.makeToast('warning', 'Ошибка сохранения нового элемента', `${response.status} - ${response.statusText}`);
        }
    }

    private async saveDictItem(dict: string, savedDictItem: any) {
        let response: any;
        try {
            const url = `/api/budget-programs/${dict}`;
            response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *client
                body: JSON.stringify(savedDictItem) // body data type must match "Content-Type" header
            });
        } catch (error) {
            this.makeToast('danger', 'Ошибка сохранения', error.toString());
        } finally {
            return response;
        }
    }

    private modalDataIsSavable = false;

    private onChangeRecord(value: string) {
        this.$nextTick(() => {
            this.$set(this, 'modalDataIsSavable', value.length > 0);
        });
    }

    private modalAppendDictItemUnit: any | null = null;
    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
    // очистка инпутов после добавление записи в справочник
    private resetFields() {
        this.modalAppendDictItem.name_kk = '';
        this.modalAppendDictItem.name_ru = '';
        this.modalAppendDictItemUnit = null;
    }

    // поиск в модальном окне
    private searchLine = '';
    private onSearch(type: string) {
        if (type === 'project') {
            const fieldName = this.isBipProgram ? 'name' : 'name_ru';
            this.filteredProjects = this.filteredProjects.map((item: any) => {
                if (item[fieldName].toLowerCase().indexOf(this.searchLine.toLowerCase()) === -1) {
                    return { ...item, filtered: true };
                }
                return { ...item, filtered: false };
            });
        }
        if (type === 'indicator') {
            this.filteredIndicators = this.filteredIndicators.map((item: any) => {
                if (item.name_ru.toLowerCase().indexOf(this.searchLine.toLowerCase()) === -1) {
                    return { ...item, filtered: true };
                }
                return { ...item, filtered: false };
            });
        }
    }

    // ======= поиск дублей в табл ппр ========
    private areThereDoubles(regionChanged = false, row = null) {
        if (row) {
            this.editedRow = row;
            if (row!['project']) {
                this.selectedItem = row!['project'];
            }
        }
        let flag = false;
        let doubleField = '';
        this.hierarchDriData.forEach((dri: any) => {
            if (dri.id !== this.editedRow.id) {
                if (this.modalAppendDictItem.editCol === 'project' || regionChanged) {
                    if (dri.project
                        && dri.project.name_ru
                        && this.selectedItem
                        && this.selectedItem.name_ru
                        && dri.project.name_ru.trim() === this.selectedItem.name_ru.trim()) {
                        if (dri.region && this.editedRow.region && dri.region === this.editedRow.region) {
                            if (dri.indicator
                            && dri.indicator.name_ru
                            && this.editedRow.indicator
                            && this.editedRow.indicator.name_ru
                            && dri.indicator.name_ru === this.editedRow.indicator.name_ru) {
                                doubleField = 'region';
                                flag = true;
                                if (regionChanged) {
                                    this.hierarchDriData.map((item: any) => {
                                        if (item.id === this.editedRow.id) {
                                            item.region = undefined;
                                            return item;
                                        }
                                        return item;
                                    });
                                }
                            }
                        }
                    }
                }

                if (this.modalAppendDictItem.editCol === 'indicator') {
                    if (dri.indicator
                        && dri.indicator.name_ru
                        && this.selectedItem
                        && this.selectedItem.name_ru
                        && dri.indicator.name_ru === this.selectedItem.name_ru) {
                        if (dri.project
                            && dri.project.name_ru
                            && this.editedRow.project
                            && this.editedRow.project.name_ru
                            && dri.project.name_ru === this.editedRow.project.name_ru) {
                            
                            const regionExistAndEqual = dri.region && this.editedRow.region && dri.region === this.editedRow.region;
                            const regionNonExist = !dri.region && !this.editedRow.region;
                            if (regionExistAndEqual || regionNonExist) {
                                doubleField = 'indicator';
                                flag = true;
                            }
                        }
                    }
                }
            }
        });
        switch (doubleField) {
            case 'region':
                this.makeToast('danger', 'Ошибка', 'Комбинация Город/Район/МСУ + Проект/Мероприятие + Показатель ППР  уже существует');
                this.selectedItem = null;
                break;
            case 'indicator':
                this.makeToast('danger', 'Ошибка', 'Комбинация Город/Район/МСУ + Проект/Мероприятие + Показатель ППР  уже существует');
                break;
            default:
                break;
        }
        return flag;
    }
    // редактирование показателя или проекта
    private editedRow: any | null = null;
    private selectedItem: any | null = null;
    private onRecordChange(e: any) {
        if (this.areThereDoubles()) {
            e.preventDefault();
            return;
        }
        if (this.modalAppendDictItem.editCol === 'project') {
            this.hierarchDriData = this.hierarchDriData.map((item: any) => {
                if (item.id === this.editedRow.id) {
                    if (this.selectedItem) {
                        return { ...item, project: this.selectedItem };
                    }
                    return { ...item, project: undefined };
                }
                return item;
            });
            this.data.driData = this.data.driData.map((item: any) => {
                if (item.id === this.editedRow.id) {
                    if (this.selectedItem) {
                        if (this.filter.bpState !== 1) {
                            return { ...item, project: this.selectedItem };
                        }
                        item.bip = this.selectedItem.id;
                        item.bipObj = this.selectedItem;
                        return item;
                    }
                    return { ...item, project: undefined };
                }
                return item;
            });
            this.updateSeeTable();
        } else if (this.modalAppendDictItem.editCol === 'indicator') {
            this.hierarchDriData = this.hierarchDriData.map((item: any) => {
                if (item.id === this.editedRow.id) {
                    if (this.selectedItem) {
                        return { ...item, indicator: this.selectedItem };
                    }
                    return { ...item, indicator: undefined };
                }
                return item;
            });
            this.data.driData = this.data.driData.map((item: any) => {
                if (item.id === this.editedRow.id) {
                    if (this.selectedItem) {
                        return { ...item, indicator: this.selectedItem };
                    }
                    return { ...item, indicator: undefined };
                }
                return item;
            });
        }
        this.$bvModal.hide('edit-project-modal')
        this.$bvModal.hide('edit-indicator-modal')
        this.selectedItem = null;
        this.$emit('onDataChanged');
    }

    // ============ очистка данных поиска в модальном окне ======
    private onResetModal() {
        this.searchLine = '';
        this.onSearch(this.modalAppendDictItem.editCol);
    }

    // ======== поиск похожих наименований =======
    private relativeItemsListRu: any[] = [];
    private relativeItemsListKk: any[] = [];
    private whichHintShow: null | string = null;
    private resetHintsList() {
        this.relativeItemsListRu.length = 0;
        this.relativeItemsListKk.length = 0;
        this.whichHintShow = null;
    }
    // поиск существующих значений в справочнике программ или индикаторов
    private popUpListGenerator(e: any, lang: string, action: string) {
        if (e.key === "Enter") {
            e.preventDefault();
        }
        let allItemsList: any[];
        switch (this.modalAppendDictItem.editCol) {
            case 'project':
                allItemsList = this.filteredProjects;
                break;
            case 'indicator':
                allItemsList = this.filteredIndicators;
                break;
            default:
                allItemsList = [];
        }
        const atrName = 'name_' + lang;
        let substrText = '';
        if (action === 'delete') {
            substrText = this.modalAppendDictItem[atrName];
        }
        if (action === 'edit') {
            if (lang === 'ru') {
                substrText = this.editedNameRu;
            }
            if (lang === 'kk') {
                substrText = this.editedNameKk;
            }
        }

        const arrItems: any[] = [];
        for (const item of allItemsList) {
            const strText = item[atrName];
            if (substrText && strText && strText.toLowerCase().indexOf(substrText.toLowerCase()) === 0) {
                arrItems.push(strText);
            }
            if (arrItems.length === 10) {
                break;
            }
        }
        if (lang === 'ru') {
            this.relativeItemsListRu = arrItems;
        }
        if (lang === 'kk') {
            this.relativeItemsListKk = arrItems;
        }
    }
    // ======= добавдение в таблицу объекта с БИП =============
    private addBibObjectIntoTable(arr: any) {
        if (arr.length > 0) {
            arr.forEach((row: any) => {
                if (this.isBipProgram && row.bip && row.bip.length > 0) {
                    row.project = this.projects.filter((bip: any) => bip.id === row.bip)[0];
                }
            });
        }
        return arr;
    }

    // ======= построение иерархической структуры таблицы=====
    private hierarchDriData: any = [];
    private ifRegion = true;
    private hierarchTablePrepare() {
        this.isAllOpen = false;
        this.hierarchDriData.length = 0;
        if (this.data && this.data.brbp) {
            if (this.data.brbp.driByRegion !== undefined) {
                this.ifRegion = Boolean(this.data.brbp.driByRegion);
            }
        }
        if (!this.data.driData || this.data.driData.length === 0 || (this.data.driData.length === 1 && Object.keys(this.data.driData[0]).length === 0)) {
            // this.addRow();
            
            return;
        }
        const driProjects: any[] = [];
        const driRegions: any[] = [];
        const driRecords: any[] = [];

        this.addBipObjectTo(this.data.driData);
        this.addBipObjectTo(this.data.seeData);
        

        const newDriData = this.addBibObjectIntoTable(JSON.parse(JSON.stringify(this.data.driData)));
        const pushRecord = (recType: string, id: number, region: string, project: any, type: number) => {
            const data = {
                id,
                region,
                project,
                type,
                open: false,
                collapsed: this.ifRegion ? type === this.projectLevelType : false
            };
            if (recType === 'project') {
                driProjects.push(data);
            }
            if (recType === 'region') {
                driRegions.push(data);
            }
        };
        newDriData.forEach((dri: any, i: number) => {
            driRecords.push({
                ...dri,
                type: this.childLevelType,
                open: false,
                collapsed: true
            });

            if (driRegions.length > 0 && this.ifRegion) {
                let isDouble = false;
                for (const item of driRegions) {
                    if (item.region === dri.region) {
                        isDouble = true;
                    }
                }
                if (!isDouble) {
                    pushRecord('region', i - 10000000, dri.region, { id: i - 10000 }, this.regionLevelType);
                }
            } else {
                pushRecord('region', i - 10000000, dri.region, { id: i - 10000 }, this.regionLevelType);
            }

            if (driProjects.length > 0) {
                let isDouble = false;
                for (const item of driProjects) {
                    if (item.region === dri.region && item.project && dri.project && item.project.id === dri.project.id) {
                        isDouble = true;
                    }
                    if (!this.ifRegion && item.project && dri.project && item.project.id === dri.project.id) {
                        isDouble = true;
                    }
                    if (!this.ifRegion && item.project === dri.project) {
                        isDouble = true;
                    }
                }
                if (!isDouble) {
                    pushRecord('project', i - 10000, dri.region, dri.project, this.projectLevelType);
                }
            } else {
                pushRecord('project', i - 10000, dri.region, dri.project, this.projectLevelType);
            }
        });
        this.hierarchDriData = [...driRegions, ...driProjects, ...driRecords]
        this.hierarchDriData.sort((a: any, b: any) => {
            let res = 0;
            let prj = 1;
            if (a.project && b.project) {
                prj = (b.project.id < a.project.id ? 1 : 0) - (a.project.id < b.project.id ? 1 : 0);
            }
            if (!a.project && !b.project) {
                prj = 0;
            }
            if (a.project && !b.project) {
                prj = -1;
            }
            if (!a.project && b.project) {
                prj = 1;
            }

            let reg = 1;
            if (a.region && b.region) {
                reg = (parseInt(b.region) < parseInt(a.region) ? 1 : 0) - (parseInt(a.region) < parseInt(b.region) ? 1 : 0);
            }
            if (!a.region && !b.region) {
                reg = 0;
            }
            if (!a.region && b.region) {
                reg = 1;
            }
            if (a.region && !b.region) {
                reg = -1;
            }
            if (this.ifRegion) {
                res = (
                    reg
                    || prj
                    || (b.type < a.type ? 1 : 0) - (a.type < b.type ? 1 : 0)
                );
            } else {
                res = (prj || (b.type < a.type ? 1 : 0) - (a.type < b.type ? 1 : 0));
            }
            return res;
        });

        this.addRowNumber();
        this.updateSeeTable();
    }

    private updateSeeTable() {
        if (!this.data.driData || this.data.driData.length === 0) {
            this.data.seeData.splice(0);
            this.data.peeData.splice(0);
        }
        const seeTable = this.$refs.seeTable as Vue & { prepareTabledata: () => void };
        seeTable.prepareTabledata();
    }

    private addBipObjectTo(driData: any) {
        if (this.isBipProgram && this.projects.length && driData.length) {
            driData.forEach((dri: any) => {
                const bipObj = this.projects.find((bip: any) => bip.id === dri.bip);
                this.$set(dri, 'bipObj', bipObj);
            })
        }
    }

    private get isBipProgram(): boolean {
        return this.filter.bpState === 1
    }

    private get lng(): String {
        let lng = 'ru';
        if (this.$i18n.locale === 'kk') lng = 'kk';
        return lng;
    }

    private ifAllFieldFilled(row: any): boolean {
        if (row.validationResult) {
            for (const isOk in row.validationResult) {
                if (!isOk) return false;
            }
        }
        return true;
    }
    // генерация номера строки для таблицы
    private addRowNumber(dataArr = this.hierarchDriData) {
        let firstLvl = 0;
        let secontLvl = 0;
        let thirdLvl = 0;
        dataArr.forEach((row: any) => {
            switch (row.type) {
                case 1:
                    if (this.ifRegion) {
                        ++firstLvl;
                        row.rowNumber = `${firstLvl}`;
                        secontLvl = 0;
                    }
                    break;
                case this.projectLevelType:
                    ++secontLvl
                    row.rowNumber = this.ifRegion ? `${firstLvl}.${secontLvl}` : `${secontLvl}`;
                    thirdLvl = 0;
                    break;
                case this.childLevelType:
                    ++thirdLvl;
                    row.rowNumber = this.ifRegion ? `${firstLvl}.${secontLvl}.${thirdLvl}` : `${secontLvl}.${thirdLvl}`;
                    break;
                default:
                    break;
            }
        });
    }
    // открытие\закрытие всех строк таблицы
    private isAllOpen = false;
    private openAll() {
        this.setOffset();
        this.isAllOpen = !this.isAllOpen;
        if (!this.isAllOpen) {
            this.hierarchDriData.forEach((row: any) => {
                if (row.type === this.regionLevelType && this.ifRegion) {
                    row.collapsed = false;
                    row.open = false;
                } else if (row.type === this.projectLevelType && !this.ifRegion) {
                    row.collapsed = false;
                    row.open = false;
                } else {
                    row.collapsed = true;
                    row.open = false;
                }
            });
        } else {
            this.hierarchDriData.forEach((row: any) => {
                row.collapsed = false;
                row.open = true;
            });
        }
    }
    // открытие\скрытие дочерних элементов
    private toggleChilds(item: any, type: number, arr: any) {
        arr = arr.map((row: any) => {
            if (type === this.projectLevelType) {
                if (row.region === item.region && (row.type === type)) {
                    row.collapsed = !row.collapsed;
                }
                if (row.region === item.region && !item.open) {
                    if (row.type === type) {
                        row.open = false;
                    }
                    if (row.type === type + 1) {
                        row.collapsed = true;
                        row.open = false;
                    }
                }
            }
            if (type === this.childLevelType) {
                if (row.project && item.project && row.region === item.region && row.project.id === item.project.id && (row.type === type)) {
                    row.collapsed = !row.collapsed;
                }
                if (row.project && item.project && row.region === item.region && row.project.id === item.project.id && !item.open) {
                    if (row.type === type) {
                        row.open = false;
                    }
                }
                if (!row.project && !item.project && row.region === item.region && (row.type === type)) {
                    row.collapsed = !row.collapsed;
                }
            }
            if (row.id === item.id) {
                row.open = !row.open;
            }
            return row;
        });
    }
    // запись данных в таблицу dri при изменении
    private onChangeData(e: any, row: any, column: keyof BrbpDri) {
        if (column !== 'noteRu' && column !== 'region') {
            e = this.parseVal(e);
            this.$set(row, column.toString(), e); //row[column] = e;
        }
        this.data.driData.filter((item: any) => item.id === row.id)[0][column] = e;
        this.$emit('onDataChanged');
    }

private keyPress(event: any, pattern: string) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
    } // вводит по заданному паттерну

    private parseVal(value: string) {
        return parseFloat(parseFloat(value.replace(",", ".").replace(/\s/g, '')).toFixed(2));
    }

 // блокировка разделов в зависимости от решения
    private get blockDescrInfl() {
        const codesAllowingEditing = ['2', '3', null, undefined];
        if (this.data.brbp && codesAllowingEditing.includes(this.data.brbp.conclDescrInflCode)) return null;
        return 'budgetprogramdisabled';
    }

    private onModaldbClick(evnt: any) {
        if (evnt.target.classList.contains("custom-control-label") || evnt.target.parentElement.classList.contains("custom-control-label")) {
            this.onRecordChange(evnt);
        }
    } // создание программы по двойному щелчку
    
    private isFieldNotValid(row: any, fieldName: string): boolean {
        if (['region', 'project', 'indicator'].includes(fieldName)) {
            return !row[fieldName];
        } else if (['finalResult'].includes(fieldName)) {
            const hasFinalResults = this.finalResults.length > 0;
            return hasFinalResults && !row[fieldName];
        } else {
            const moneyFields = ['reportYear', 'correctPlan', 'planPeriod1', 'planPeriod2', 'planPeriod3'];
            let moneySum = 0;
            moneyFields.forEach((moneyFieldName: string) => {
                moneySum += row[moneyFieldName] ? Number(row[moneyFieldName]) : 0;
            })
            return moneySum === 0;
        }
    }

    private canDictBeEditChecker = new CanDictBeEditChecker(this);
    private isSearchingExistedDictItems = false;
    private async onDelProjectClick() {
        this.isSearchingExistedDictItems = true;
        const isProjectExist = await this.searchingExistedProjects();
        if (isProjectExist) {
            this.makeToast('warning', 'Ошибка', `Проект не может быть удален, т.к. он уже используется в ППР`);
        } else this.$bvModal.show('del-item-modal');
        this.isSearchingExistedDictItems = false;
    }

    private async onEditProjectClick() {
        this.isSearchingExistedDictItems = true;
        const isProjectExist = await this.searchingExistedProjects();
        if (isProjectExist) {
            this.makeToast('warning', 'Ошибка', `Проект не может быть изменен, т.к. он уже используется в ППР`);
        } else {
            this.fillFields();
            this.$bvModal.show('modal-edit-dict-item');
        }
        this.isSearchingExistedDictItems = false;
    }

    private async searchingExistedProjects() {
        await this.canDictBeEditChecker.getExistingDriProject(this.selectedItem.id);
        return this.canDictBeEditChecker.isProjectExist;
    }

    private isSearchingExistedIndicators = false;
    private async onDelIndicatorClick() {
        this.isSearchingExistedIndicators = true;
        const isIndicatorExist = await this.searchingExistedIndicators();
        if (isIndicatorExist) {
            this.makeToast('warning', 'Ошибка', `Показатель не может быть удален, т.к. он уже используется в ППР`);
        } else this.$bvModal.show('del-item-modal');
        this.isSearchingExistedIndicators = false;
    }

    private async onEditIndicatorClick() {
        this.isSearchingExistedIndicators = true;
        const isIndicatorExist = await this.searchingExistedIndicators();
        if (isIndicatorExist) {
            this.makeToast('warning', 'Ошибка', `Показатель не может быть изменен, т.к. он уже используется в ППР`);
        } else {
            this.fillFields();
            this.$bvModal.show('modal-edit-dict-item');
        }
        this.isSearchingExistedIndicators = false;
    }

    private async searchingExistedIndicators() {
        await this.canDictBeEditChecker.getExistingDriIndicator(this.selectedItem.id);
        return this.canDictBeEditChecker.isIndicatorExist;
    }

}
