export class DependentRowsDeleter {
    hierarchyData: any;
    ifRegion: boolean;
    filter: any;


    constructor(
        hierarchyData: any,
        ifRegion: boolean,
        filter: any,
    ) {
        this.hierarchyData = hierarchyData;
        this.ifRegion = ifRegion;
        this.filter = filter;
    }

    private regionLevelType = 1;
    private projectLevelType = 2;
    private childLevelType = 3;

    private get isBipProgram(): boolean {
        return this.filter.bpState === 1
    }

    public deleteDependentRows(row: any): void {
        const region = row.region;
        const isRegionColInTable = this.ifRegion;
        if (isRegionColInTable) {
            const isHasOtherChildInRegion = this.deleteRowsByRegion(row, region);

            if (isHasOtherChildInRegion) {
                this.deleteRowsByRegionAndProject(row, region);
            }
        } else {
            this.deleteRowsByProject(row, region);
        }
    }

    private deleteRowsByRegion(row: any, region: string): boolean {
        const thisRerionChildIndex = this.hierarchyData.findIndex((item: any) => {
            const isOtherChildInThisRegionExist = item.type === this.childLevelType && item.id !== row.id && item?.region === region;
            return isOtherChildInThisRegionExist;
        })

        const isOtherChildInThisRegion = thisRerionChildIndex !== -1;
        if (!isOtherChildInThisRegion) {
            for (let i = this.hierarchyData.length - 1; i >= 0; i--) {
                const curRow = this.hierarchyData[i];
                if (curRow.region === region) this.hierarchyData.splice(i, 1);
            }
        }

        return isOtherChildInThisRegion;
    }

    private deleteRowsByRegionAndProject(row: any, region: string) {
        const thisRerionAndProjectChildIndex = this.hierarchyData.findIndex((item: any) => {
            const isOtherChildInThisRegionExist = item.type === this.childLevelType && item.id !== row.id && item?.region === region;
            const isItemHasSameProject = this.isBipProgram ? item.bip === row.bip : item.project === row.project;
            return isOtherChildInThisRegionExist && isItemHasSameProject;
        })

        const isOtherChildInThisRegionAndProject = thisRerionAndProjectChildIndex !== -1;
        if (!isOtherChildInThisRegionAndProject) {
            for (let i = this.hierarchyData.length - 1; i >= 0; i--) {
                const curRow = this.hierarchyData[i];
                if (curRow.type === this.regionLevelType) continue;
                const isRowHasSameProject = this.isBipProgram 
                                            ? (curRow.bip === row.bip || curRow.project?.id === row.bip)
                                            : (curRow.project === row.project || (!row.project && curRow.project?.id < 0));
                
                if (curRow.region === region && isRowHasSameProject) {
                    this.hierarchyData.splice(i, 1);
                }
            }
        } else {
            this.deleteCurrentItem(row);
        }
    }

    private deleteRowsByProject(row: any, region: string) {
        const thisProjectChildIndex = this.hierarchyData.findIndex((item: any) => {
            const isOtherChild = item.type === this.childLevelType && item.id !== row.id;
            const isItemHasSameProject = this.isBipProgram ? item.bip === row.bip : item.project === row.project;
            return isOtherChild && isItemHasSameProject;
        })

        const isOtherChildInThisProject = thisProjectChildIndex !== -1;
        if (!isOtherChildInThisProject) {
            for (let i = this.hierarchyData.length - 1; i >= 0; i--) {
                const curRow = this.hierarchyData[i];
                const isRowHasSameProject = this.isBipProgram 
                                            ? curRow.bip === row.bip 
                                            : (curRow.project === row.project || (!row.project && curRow.project?.id < 0));
                
                if (isRowHasSameProject) {
                    this.hierarchyData.splice(i, 1);
                }
            }
        } else {
            this.deleteCurrentItem(row);
        }
    }

    public deleteCurrentItem(row: any): void {
        const hindex = this.hierarchyData.findIndex((item: any) => item.id === row.id);
        this.hierarchyData.splice(hindex, 1);
    }

    public clearCurrentItem(row: any, fieldsToClear: string[]): void {
        fieldsToClear.forEach((field: string) => {
            row[field] = null;
        })
    }

    public checkIfOtherChildrenExist(row: any): boolean {
        const childrenWithSameRegionAndProject = this.hierarchyData.filter((item: any) => {
            const isOtherChild = item.id !== row.id && item.type === this.childLevelType;
            if (isOtherChild) {
                const isOtherChildHasSameRegion = item?.region === row?.region;
                const isItemHasSameProject = this.isBipProgram ? item.bip === row.bip : item.project?.id === row.project?.id;
                return isOtherChildHasSameRegion && isItemHasSameProject;
            }
            return false;
        })

        return childrenWithSameRegionAndProject.length > 0;
    }
}

export class MakeToast {
    public makeToast(vue: any, variant: any, title: string, tostbody: any) {
        vue.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        })
    }
}

export class CanDictBeEditChecker extends MakeToast {
    vue: any;
    constructor(
        vue: any
    ) {
        super();
        this.vue = vue;
    }

    public isProjectExist = true;
    public async getExistingDriProject(prjId: number) {
        try {
            const response = await fetch(`/api-py/get-existing-dri-project-by-id/${prjId}`);
            if (response.status === 200) {
                const result = await response.json();
                this.isProjectExist = result;
            } else {
                this.makeToast(this.vue, 'danger', 'Ошибка', `Ошибка загрузки справочника уникальных проектов. Error: ${response.status}`);
            }
        } catch (error) {
            this.makeToast(this.vue, 'danger', 'Ошибка', `Ошибка загрузки справочника уникальных проектов ${error.toString()}`);
        }
    }

    public isIndicatorExist = true;
    public async getExistingDriIndicator(indId: number) {
        try {
            const response = await fetch(`/api-py/get-existing-dri-indicator-by-id/${indId}`);
            if (response.status === 200) {
                const result = await response.json();
                this.isIndicatorExist = result;
            } else {
                this.makeToast(this.vue, 'danger', 'Ошибка', `Ошибка загрузки справочника уникальных показателей. Error: ${response.status}`);
            }
        } catch (error) {
            this.makeToast(this.vue, 'danger', 'Ошибка', `Ошибка загрузки справочника уникальных показателей ${error.toString()}`);
        }
    }

    public isEffectExist = true;
    public async getExistingSeeEffect(seeId: number) {
        try {
            const response = await fetch(`/api-py/get-existing-see-effect-by-id/${seeId}`);
            if (response.status === 200) {
                const result = await response.json();
                this.isEffectExist = result;
            } else {
                this.makeToast(this.vue, 'danger', 'Ошибка', `Ошибка загрузки справочника уникальных эффектов. Error: ${response.status}`);
            }
        } catch (error) {
            this.makeToast(this.vue, 'danger', 'Ошибка', `Ошибка загрузки справочника уникальных эффектов ${error.toString()}`);
        }
    }
}