import { Component, Vue } from "vue-property-decorator";
import {
    BrbpPprWrapper,
    BrbpWrapper,
    IBrbpWrapper
} from '@/modules/budget-request/components/js/brbp-wrapper';

interface EmptyRequiredFields {
    isRegionFilled?: Boolean,
    isBipFilled?: Boolean,
    isProjectFilled?: Boolean,
    isAnyMoneyFieldFilled?: Boolean
}

interface EmptyRequiredFieldsDri extends EmptyRequiredFields {
    isIndicatorFilled?: Boolean,
    isFinalResultFilled?: Boolean
}

interface EmptyRequiredFieldsSee extends EmptyRequiredFields {
    isEffectFilled?: Boolean
}

@Component
export default class BudgerRequestDataValidationMixin extends Vue {
    public brbpWrapper: BrbpWrapper = new BrbpWrapper();
    public curFinalResults: any[] = [];
    public regions: any[] = [];
    public filter: any = [];

    //  ==================== Валидация данных ==========================
    private validationMsgs: string[] = [];
    public ifAllRequestedFieldsFilled(): any {
        this.validationMsgs.splice(0);
        this.fillValidationMsgsMain(this.validationMsgs);
        
        let isTableDataValid = true;
        if (this.brbpWrapper.pprData?.length) {
            this.brbpWrapper.pprData.forEach(ppr => {
                isTableDataValid = this.checkAndFillValidatioMassages(ppr, isTableDataValid);
            });
        } else {
            isTableDataValid = this.checkAndFillValidatioMassages(this.brbpWrapper, isTableDataValid);
        }

        if (this.validationMsgs.length > 0) {
            this.showValidationToast('warning', 'Проверьте заполнение данных', this.validationMsgs);
            return false;
        }

        return true;
    }

    private checkAndFillValidatioMassages(data: any, isTableDataValid: boolean): boolean {
        const isFilled = this.checkIfPprFieldsFilled(data)
        isTableDataValid = isTableDataValid && isFilled;
        if (!isTableDataValid) {
            this.fillValidationMsgs(data);
        }
        return isTableDataValid;
    }



    public fillValidationMsgsMain(validationMsgs: string[]) {

    }

    private checkIfPprFieldsFilled(ppr: any): boolean {
        let isCheckPassed = true;

        isCheckPassed = this.isDriDataPassedValidation(ppr, isCheckPassed);
        isCheckPassed = this.isSeeDataPassedValidation(ppr, isCheckPassed);

        return isCheckPassed;
    }   

    public isDriDataPassedValidation(ppr: BrbpPprWrapper, isCheckPassed: boolean): boolean {
        if (ppr.driData?.length > 0) {
            const isPprByRegion = ppr.brbp?.driByRegion;
            
            ppr.driData.forEach((dri: any) => {
                const emptyRequiredFieldsDri: EmptyRequiredFieldsDri = {};
                this.$set(emptyRequiredFieldsDri, 'isRegionFilled', !(isPprByRegion && !dri.region));
                
                if (this.isBipProgram) {
                    this.$set(emptyRequiredFieldsDri, 'isBipFilled', !!(dri.bip));
                } else {
                    this.$set(emptyRequiredFieldsDri, 'isProjectFilled', !!(dri.project && dri.project.id));
                }

                this.$set(emptyRequiredFieldsDri, 'isIndicatorFilled', !!(dri.indicator && dri.indicator.id));

                const hasFinalResults = this.curFinalResults.length > 0;
                this.$set(emptyRequiredFieldsDri, 'isFinalResultFilled', !hasFinalResults || !!dri.finalResult);

                this.$set(emptyRequiredFieldsDri, 'isAnyMoneyFieldFilled', !!(dri.reportYear && Number(dri.reportYear) > 0)
                                                                        || !!(dri.correctPlan && Number(dri.correctPlan) > 0)
                                                                        || !!(dri.planPeriod1 && Number(dri.planPeriod1) > 0)
                                                                        || !!(dri.planPeriod2 && Number(dri.planPeriod2) > 0)
                                                                        || !!(dri.planPeriod3 && Number(dri.planPeriod3) > 0));
                
                this.$set(dri, 'validationResult', emptyRequiredFieldsDri);
                if (isCheckPassed) {
                    isCheckPassed = this.getIsCheckPassed(emptyRequiredFieldsDri);
                }
            })
        }

        return isCheckPassed;
    }

    public isSeeDataPassedValidation(ppr: BrbpPprWrapper, isCheckPassed: boolean, isEmptyRowShouldBeDeleted = true): boolean {
        if (ppr.seeData?.length > 0) {
            const isPprByRegion = ppr.brbp?.driByRegion;
            for (let i = ppr.seeData.length - 1; i >= 0; i--) {
                const see = ppr.seeData[i];
                const emptyRequiredFieldsSee:EmptyRequiredFieldsSee = {};

                this.$set(emptyRequiredFieldsSee, 'isRegionFilled', !(isPprByRegion && !see.region));
                
                if (this.isBipProgram) {
                    this.$set(emptyRequiredFieldsSee, 'isBipFilled', !!(see.bip));
                } else {
                    this.$set(emptyRequiredFieldsSee, 'isProjectFilled', !!(see.project && see.project.id));
                }

                this.$set(emptyRequiredFieldsSee, 'isEffectFilled', !!(see.see && see.see.id));
 
                this.$set(emptyRequiredFieldsSee, 'isAnyMoneyFieldFilled', !!(see.effPlanPeriod1 && Number(see.effPlanPeriod1) > 0)
                                                                        || !!(see.effPlanPeriod2 && Number(see.effPlanPeriod2) > 0)
                                                                        || !!(see.effPlanPeriod3 && Number(see.effPlanPeriod3) > 0));
                
                const isRowEmpty =  !emptyRequiredFieldsSee.isEffectFilled && !emptyRequiredFieldsSee.isAnyMoneyFieldFilled;
                    
                if (isEmptyRowShouldBeDeleted && isRowEmpty) {
                    ppr.seeData.splice(i, 1);
                } else {
                    this.$set(see, 'validationResult', emptyRequiredFieldsSee);

                    if (!isRowEmpty && isCheckPassed) {
                        isCheckPassed = this.getIsCheckPassed(emptyRequiredFieldsSee);
                    }
                }
                
            }
        }

        return isCheckPassed;
    }

    private fillValidationMsgs(ppr: any): void {
        const pprCode = ppr.brbp?.ppr;
        const isPprByRegion = ppr.brbp?.driByRegion;

        if (ppr.driData?.length > 0) {

            ppr.driData.forEach((dri: any) => {
                const data = {data: dri, pprCode, isPprByRegion, tableName: 'ППР'};

                const errMessage = this.errorMessageBuild(data);
            
                if (errMessage) this.validationMsgs.push(errMessage);
            })
        }


        if (ppr.seeData?.length > 0) {
            
            ppr.seeData.forEach((see: any) => {
                const data = {data: see, pprCode, isPprByRegion, tableName: 'ПЭЭ'};
                let errMessage = this.errorMessageBuild(data);

                const isEffectFilled = see.validationResult.hasOwnProperty('isEffectFilled') && see.validationResult.isEffectFilled;
                const isAnyMoneyFieldFilled = see.validationResult.hasOwnProperty('isAnyMoneyFieldFilled') && see.validationResult.isAnyMoneyFieldFilled;
                if (isEffectFilled && !isAnyMoneyFieldFilled) {
                    const seeLoclName = see.see['name_' + this.lng];

                    errMessage += `, Эффект": ${seeLoclName}`;
                }

                if (errMessage) this.validationMsgs.push(errMessage);
            })            
        }
    }

    private errorMessageBuild({ data, pprCode, isPprByRegion, tableName }: { data: any; pprCode: Number; isPprByRegion: Boolean, tableName: string }): string {
        let errMessage = '';
        const valRes = data.validationResult;
        const bipOrProjectField = this.isBipProgram ? 'bipObj' : 'project';
        const isHasNotFilledData = valRes && Object.values(valRes).some(value => value === false)
        if (isHasNotFilledData) {
            if (pprCode) {
                errMessage = `ППР: ${pprCode}, `
            }
            errMessage += `Табл. ${tableName},  `

            const isRegionFilled = valRes.hasOwnProperty('isRegionFilled') &&  valRes.isRegionFilled
            if (isPprByRegion) {
                if (isRegionFilled) {
                    const regionLoclName = this.regions.find((reg: any) => reg.code === data.region)['name_' + this.lng];
                    errMessage += `Регион: ${regionLoclName}, `;
                } else {
                    errMessage += `Регион, `;
                }
            }
            
            if (!!data[bipOrProjectField]) {
                errMessage += `ПР: ${data[bipOrProjectField]['name_' + this.lng]}`;
            } else {
                if (this.isBipProgram) {
                    errMessage += 'Мероприятие';
                } else {
                    errMessage += 'Проект';
                }
            }
        }

        return errMessage;
    }

    private count = 0
    private showValidationToast(variant: any, title: string, tostbody: any) {
        const h = this.$createElement;
        const id = `my-toast-${this.count++}`;
        const msgs: any[] = [];

        tostbody.forEach((msg: string) => {
           msgs.push(h('p', {}, '- ' + msg))
        })
        

        // Create the toast
        this.$bvToast.toast(msgs, {
          id: id,
          title: title,
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000,
          appendToast: true,
          noHoverPause: false
        })
    }

    private getIsCheckPassed(fields: any) {
        for (const check in fields) {
            const checkFailed = !fields[check]
            if (checkFailed) return false;
        }
        return true;
    }
    //  ==================== Валидация данных ==========================

    private get isBipProgram(): boolean {
        return this.filter.bpState === 1
    }

    public get lng(): String {
        let lng = 'ru';
        if (this.$i18n.locale === 'kk') lng = 'kk';
        return lng;
    }

    


}