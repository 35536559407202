import { Component, Vue } from "vue-property-decorator";

@Component
export default class BudgerRequestMixin extends Vue {
    public comparePrjOrBip(item1: any, item2: any, data: any, curBpState: boolean, mark?: string) {
        const isHasBipOrPrj = !!(curBpState ? item2.bip : item2.project)
        const isShoudlHasRegion = Boolean(data.brbp.driByRegion);
        const isDriHasRegionAndProjectOrBip = isShoudlHasRegion ? item2.region && isHasBipOrPrj : isHasBipOrPrj;
        if (!isDriHasRegionAndProjectOrBip) return false;
        
        let res = true;
        if (data.brbp && data.brbp.driByRegion === true) {
            res = item1.region === item2.region;
        }

        if (!curBpState) {
            
            if (item1.project && item2.project) {
                res = res && item1.project.id === item2.project.id;
            }
            if (!item1.project === null  && !item2.project) {
                res = res && true;
            }
            if ((!item1.project && item2.project) || (item1.project && item1.project !== null && !item2.project)) {
                return false;
            }
            return res;
        }
        res = res && item1.bip === item2.bip;
        return res;
    }

    public roundNumber(input: number, decimalPlaces: number): number {
        const numberValue = Number(input) || 0;
        const decimalPlacesValue = Number(decimalPlaces) || 0;
        const rank = Math.pow(10, decimalPlacesValue);
        const mult = Math.round(numberValue * rank * 100) / 100;
        const roundedNumber = Math.round(mult) / rank;
        return roundedNumber;
    }

    public curImplementWay: any | null = null;

    public get isDistributedProgram(): Boolean {
        return this.curImplementWay && this.curImplementWay.code === '02';
    }

    public get numberOfDecimalPlaces(): number {
        return this.isDistributedProgram ? 3 : 0;
    }

}