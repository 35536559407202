
















































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import store from '@/services/store';
import { CUR_YEAR } from './components/js/budgetCurYear';
import { ICurBudgetVersion, ICurDataType } from './components/js/budget-header-new-interfaces';


@Component({
    name: 'c-budget-program-filter',
    components: {
    }
})


export default class CBudgetProgramFilter extends Vue {
    @Prop({
        required: true,
        default: ''
    })
    private usrId!: String;

    @Prop({
        required: true,
        default: false
    })
    private mainTableOverlay!: boolean;
    
    @Prop({
        required: true,
        default: null
    })
    private setIdArr!: Function;

    @Prop({
        required: true,
        default: 'ru'
    })
    private lng!: any

    private isComponentFirstCreation = true;
    private async created() {
        this.watchOnDataEdit();
        this.onComponentCreated();
    }

    private async onComponentCreated() {
        this.getUrl();
        this.$emit('setProgress', 5);
        await this.loadDataTypeDict();
        this.getObl();
    }

    private urlParams: {
        region?: string,
        planYear?: number,
        abp?: number,
        variant?: string,
        prg?: number | undefined,
    } = {}

    private getUrl(): void {
        const currParams = this.$route.params;
        this.urlParams = {};

        if (currParams.region) this.$set(this.urlParams, 'region', currParams.region);
        if (currParams.planYear) this.$set(this.urlParams, 'planYear', parseInt(currParams.planYear));
        if (currParams.abp) this.$set(this.urlParams, 'abp', parseInt(currParams.abp));
        if (currParams.variant) this.$set(this.urlParams, 'variant', currParams.variant);
        if (currParams.prg) this.$set(this.urlParams, 'prg', parseInt(currParams.prg));
    }

    private watchOnDataEdit() {
        this.$watch('$route.params', () => this.onRouteChanged());
        this.$watch('$store.state.user.sub', () => { this.getObl(); this.loadBudgetLevel() });
        this.$watch('$store.state._instanceCode', this.getObl, { deep: true });
        this.$watch('curYear', () => { this.loadBudgetLevel(); });
        this.$watch('curRegion', () => { this.loadBudgetLevel(); });
    }

    private onRouteChanged() {
        if (!this.routeChangedManually) {
            this.resetFilter();
            this.isComponentFirstCreation = true;
            this.onComponentCreated();
        }
        this.routeChangedManually = false;
    }

    private resetFilter() {
        this.curYear = {};
        this.curRegion = null;
        this.curAbp = null;
        this.curBudgetVersion = null;
    }

// =========== Регион ================
    public regions: any[] = [];
    private curRegion: any | null = null;
    private regionBase: any[] = [];

    private async getObl() {
        let obl = null;
        const instCode = store.getters.instanceCode;
        try {
            const result = await fetch('/api-py/get-budget-obl/' + instCode)
            if (result.status === 200) {
                const json = await result.json();
                obl = json.obl;
            } else {
                this.$emit('makeToast', 'danger', 'get-budget-obl', `${result.status} - ${result.statusText}`);
            };
        } catch (error) {
            obl = null;
            this.$emit('makeToast', 'danger', 'Ошибка запроса get-budget-obl', error.toString());
        }
        if (!this.usrId) { return; }
        this.regionBase = [];
        if (obl !== null) {
            try {
                await fetch('/api-py/get-user-regions-by-obl/' + obl + '/' + this.usrId)
                    .then(response => response.json())
                    .then(json => {
                        if (json.length && json[0].code) {
                            json.sort((a: any, b: any) => (a.code - b.code > 0) ? 1 : -1);
                            this.regionBase = json;
                        }
                    });
            } catch (error) {
                this.regionBase = [];
                this.$emit('makeToast', 'danger', 'Ошибка запроса get-user-budget-region', (error as Error).toString());
            }
        }
        this.region();
    }

    private region() {
        const res = [];
        for (const el of this.regionBase) {
            res.push(this.setNameLang(el, 'code'));
        }
        res.sort((a, b) => (a.code - b.code > 0) ? 1 : -1);
        if (res.length > 0) { 
            if (this.urlParams.region) {
                const urlRegionIndex = res.findIndex((reg: any) => reg.code === this.urlParams.region);
                if (urlRegionIndex !== -1) this.curRegion = res[urlRegionIndex];
                else this.curRegion = res[0];
            } else this.curRegion = res[0]; 
        }
        this.regions = res;
    }

//====================== АБП ============================
    private abpBase: any[] = [];
    private curAbp: any | null = null;
    private budgetLevel: number[] = [];

    private get abp(): any[] {
        const res: any[] = [];
        for (const el of this.abpBase) {
            res.push(this.setNameLang(el, 'abp'));
        }
        return res;
    }

    private async loadBudgetLevel() { // загрузка уровня бюджета
        this.budgetLevel = []; 
        if (this.curRegion) {
            if (this.curRegion.code.slice(this.curRegion.code.length - 4) === '0101') {
                this.budgetLevel.push(2);
            } else if (this.curRegion.code.slice(this.curRegion.code.length - 2) === '01')  {
                this.budgetLevel.push(3);
            } else if (this.curRegion.code.slice(this.curRegion.code.length - 2) !== '00') {
                this.budgetLevel.push(4);
            }
        }
        this.loadAbp();
        this.$emit('onBudgetLevelLoaded');
    }

    private async loadAbp() { // загрузка АБП
        if (!this.budgetLevel || this.budgetLevel.length < 1 || !this.usrId) return; 
        let response: any = [];
        this.abpBase.splice(0);
        try {
            response = await fetch(`/api-py/get-dict-ved-abp-by-budgetlevel-user/${encodeURI(JSON.stringify(this.budgetLevel))}/${this.usrId}`);
            if (response.status === 200) {
                response = await response.json();
            } else {
                this.$emit('makeToast', 'danger', 'Ошибка загрузки адм. программ', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            response = [];
            this.$emit('makeToast', 'danger', 'Ошибка загрузки адм. программ', error.toString());
        }

        response.sort((a: any, b: any) => (a.abp - b.abp > 0) ? 1 : -1);
        this.abpBase = this.setIdArr(response, 'abp');
        if (this.abpBase.length > 0) {
            if (this.urlParams.abp) {
                const urlAbpIndex = this.abpBase.findIndex((abp: any) => abp.abp === this.urlParams.abp);
                if (urlAbpIndex !== -1) this.curAbp = this.setNameLang(this.abpBase[urlAbpIndex], 'abp');
                else this.curAbp = this.setNameLang(this.abpBase[0], 'abp');
            } else this.curAbp = this.setNameLang(this.abpBase[0], 'abp');
        } else {
            this.curAbp = null;
        }

        this.loadBudgetVersionDict()
    }


// ====================== Плановый период ========================
    private curYear: any = {};
    private get years(): any[] {
        let year = CUR_YEAR + 1;
        if (this.urlParams.planYear) {
            year = this.urlParams.planYear;
        }
        const periodLst = [];
        const startDate = 2019;
        const endDate = year + 2;
        for (let i = endDate; i >= startDate; i--) {
            periodLst.push({ name: `${i} - ${i + 2}`, year: i });
            if (year === i) {
                this.curYear = { name: `${i} - ${i + 2}`, year: i };
            }
        }
        return periodLst;
    }


// ====================== Версия бюджета ========================
    private budgetVersionDictBase: ICurBudgetVersion[] = [];
    private curBudgetVersion: ICurBudgetVersion | null = null;

    private async loadBudgetVersionDict(): Promise<void> {
        if (!this.curYear.year || !this.curRegion.code) return;
        let result: ICurBudgetVersion[] = [];
        this.budgetVersionDictBase.splice(0);
        const data = {
            report: true
        }
        try {
            const response = await fetch(`/api-py/get-variants-by-region-year/${this.curRegion.code}/${this.curYear.year}`, 
                {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(data)
                })
            if (response.status === 200) {
                result = await response.json();
                this.addStatusToVersionName(result);
                this.budgetVersionDictBase = result;
                this.setCurBudgetVersion(result);
                this.setCurDataType();
                this.onFirstCreation();
            } else {
                this.$emit('makeToast', 'danger', 'Ошибка загрузки версии бюджета', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            this.$emit('makeToast', 'danger', 'Ошибка загрузки версии бюджета', error.toString());
        }
    }

    private onFirstCreation() {
        if (this.isComponentFirstCreation) {
            // this.$emit('onCurBudgetVersionChanged', this.curBudgetVersion)
            this.$emit('setProgress', 30);
            this.$nextTick(() => {
                this.pushNewRoute();
                this.$emit('filterChanged', this.filterParams);
                this.isComponentFirstCreation = false;
            })
        }
    }

    private get variantLst(): ICurBudgetVersion[] {
        let res: ICurBudgetVersion[] = [];
        if (this.curDataType && this.curDataType.code) {
            res = this.budgetVersionDictBase.filter((item: any) => item.data_type === parseInt(this.curDataType!.code!));
            this.setCurBudgetVersion(res);
        }
        return res;
    }

    private setCurBudgetVersion(res: ICurBudgetVersion[]): void {
        if (res.length > 0) {
            if (this.urlParams.variant) {
                const currUrlVersionIndex = res.findIndex((item: any) => item.variant_uuid === this.urlParams.variant);
                if (currUrlVersionIndex !== -1) this.curBudgetVersion = res[currUrlVersionIndex];
                else this.setCurVersion(res);
            } else {
                this.setCurVersion(res);
            }
        } else {
            this.curBudgetVersion = null;
        }
    }

    private setCurVersion(res: ICurBudgetVersion[]): void {
        const activeVersion = res.find((item: any) => item.isVersionActive);
        if (activeVersion) this.curBudgetVersion = activeVersion;
        else this.curBudgetVersion = res[0];
    }

    private addStatusToVersionName(result: ICurBudgetVersion[]): void {
        result.forEach((item: ICurBudgetVersion) => {
            const isVersionActive = item.attribute === true && item.status === false;
            let status_ru = '';
            let status_kk = '';

            if (isVersionActive) {
                this.$set(item, 'isVersionActive', true);
                status_ru = '(акт)';
                status_kk = '(акт)';
            }

            const isVersionAppruved = item.attribute === false && item.status === true;
            if (isVersionAppruved) {
                this.$set(item, 'isVersionAppruved', true);
                status_ru = '(утв)';
                status_kk = '(утв)';
            }
            this.$set(item, 'name_old_ru', item.name_ru);
            this.$set(item, 'name_old_kk', item.name_kk);

            item.name_ru = `${item.name_ru} ${status_ru}`;
            item.name_kk = `${item.name_kk} ${status_kk}`;

        })
    }


    // ================= Раздел вид данных =========================
    private dataTypeDictBase: ICurDataType[] = [];
    private curDataType: ICurDataType | null = null;

     private get dataTypeDict(): ICurDataType[] {
        const result: ICurDataType[] = [];
        for (const el of this.dataTypeDictBase) {
            result.push(this.setNameLang(el));
        }
        return result;
    }

    // Загрузка справочника Видов данных
    private async loadDataTypeDict(): Promise<void> {
        let result: ICurDataType[] = [];
        this.dataTypeDictBase.splice(0);
        try {
            const response = await fetch('/api-py/dict_budget_data_types/')
            if (response.status === 200) {
                result = await response.json();
            } else {
                this.$emit('makeToast', 'danger', 'Ошибка загрузки вида данных', `${response.status} - ${response.statusText}`);
            }
        } catch (error) {
            result = [];
            this.$emit('makeToast', 'danger', 'Ошибка загрузки вида данных', (error as Error).toString());
        }
        this.dataTypeDictBase = result;
    }

    private setCurDataType(): void {
        if (this.curBudgetVersion && this.curBudgetVersion.data_type) {
            const curDT = this.dataTypeDictBase.find((item: any) => parseInt(item.code) === this.curBudgetVersion!.data_type!);
            if (curDT) this.curDataType = curDT;
        }
    }
    
    private setNameLang(obj: any, codeName?: any | null): any {
        let txt = obj['name_' + this.$i18n.locale];
        if (txt === undefined) { txt = obj.name_ru; }
        if (codeName !== undefined && codeName !== null && obj[codeName] !== undefined) {
            txt = obj[codeName] + ' - ' + txt;
        }
        const el = Object.assign({}, obj);
        el.name = txt;
        return el;
    }

    private get localLableField(): string {
        return `name_${this.lng}`;
    }

    private openFilterByRef(refName: string) {
        const drop: any = this.$refs.bpFilter;
        drop.show(true);
        const refItem: any = this.$refs[refName];
        setTimeout(() => refItem.$el.focus(), 100);
    }

    private chgData() {
        this.pushNewRoute(-1);
        this.$emit('filterChanged', this.filterParams);
        const drop: any = this.$refs.bpFilter;
        drop.hide(true);
    }

    private get filterParams() {
        return {
            urlParams: this.urlParams,
            curRegion: this.curRegion,
            curYear: this.curYear,
            curAbp: this.curAbp,
            curBudgetVersion: this.curBudgetVersion,
            curDataType: this.curDataType,
        }
    }
    
    private routeChangedManually = false;
    private pushNewRoute(prg: number | undefined = undefined): void {
        if (
            prg
            || !this.urlParams.region || this.urlParams.region !== this.curRegion.code
            || !this.urlParams.planYear || this.urlParams.planYear !== this.curYear.year
            || !this.urlParams.abp || this.urlParams.abp !== this.curAbp.abp
            || !this.urlParams.variant || this.urlParams.variant !== this.curBudgetVersion?.variant_uuid
        ) {
            this.routeChangedManually = true;
            const curParams = {
                    region: this.curRegion.code,
                    planYear: this.curYear.year,
                    abp: this.curAbp.abp,
                    variant: this.curBudgetVersion?.variant_uuid ? this.curBudgetVersion?.variant_uuid : ''
                };
            if (prg && prg >= 0) this.$set(curParams, 'prg', prg);

            const routeName = this.$route.name ? this.$route.name : '';
            this.$router.push({
                name: routeName, 
                params: curParams
            });
            this.getUrl();
        }
    }

    private handlePrgInRoute(prgId: number) {
        this.routeChangedManually = true;
        let newParams = {};
        if (prgId >= 0) {
            newParams = { 
                ...this.$route.params,
                prg: prgId
            }
        } else {
            const { prg, ...restParams } = this.$route.params;
            newParams = restParams;
        }
        const routeName = this.$route.name ? this.$route.name : '';
        this.$router.push({
            name: routeName,
            params: newParams
        });
    }

    private get isOpenBtnDisabled(): boolean {
        return this.mainTableOverlay 
                || !this.curBudgetVersion
                || !this.curDataType
                || !this.curAbp
                || !this.curYear
                || !this.curRegion
    }
}
