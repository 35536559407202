<template>
    <div class="filter-breadcrumb filter-breadcrumb_budget-programs">
        <span class="item-breadcrumb" v-if="curRegion" @click="openFilterByRef('curRegionRef')">
            {{ curRegion.code }}-РЕГИОН
        </span>
        <span class="item-breadcrumb" v-if="curYear" @click="openFilterByRef('curYearRef')">
            {{ curYear.name}}
        </span>
        <span class="item-breadcrumb" v-if="curAbp" @click="openFilterByRef('abpRef')">
            {{curAbp.abp}}-АБП
        </span>
        <span class="item-breadcrumb" v-if="curDataType && main"  @click="openFilterByRef('curDataTypeRef')">
            {{curDataType.name_ru}}
        </span>
        <span class="item-breadcrumb" v-if="curBudgetVersion"  @click="openFilterByRef('curVerRef')">
            {{curBudgetVersion.name_ru}}
        </span>
        <span class="item-breadcrumb" v-if="curPrgId && !main">
            {{curPrgId}}-БП
        </span>
        
    </div>
</template>
<script>
export default {
    name: 'CBreadCrumbs',
    props: {
        curRegion: Object,
        curYear: Object,
        curAbp: Object,
        curBudgetVersion: Object,
        curDataType: Object,
        curPrgId: {
            default: null,
            type: Number
        },
        main: {
            default: false,
            type: Boolean
        }
    },
    methods: {
        openFilterByRef(refName) {
            this.$emit('openFilterByRef', refName);
        }
    }
};

</script>
<style scoped>
    .filter-breadcrumb_budget-programs {
        height: fit-content;
        display: block !important;
    }
</style>
