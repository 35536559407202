

































































































import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {Component, Vue, Prop} from "vue-property-decorator";
import { mixins } from 'vue-class-component';
import BudgerRequestMixin from '../mixins/bp-mixin';

@Component({
    name: 'c-table-budget-expend',
    components: {
    }
})
export default class CTableBudgetExpend extends mixins(BudgerRequestMixin) {

    @Prop({
        required: true,
        default: {}
    })
    private filter!: any;

    @Prop({
        required: true,
        default: {}
    })
    private commonResources!: any;

    @Prop({
        required: true,
        default: {}
    })
    private bpExpendsData!: any;

    private compareTotals(row: any, field1: string, field2: string) {
        let areThereDiff = false;
        let totalSum = 0;
        if (this.bpExpendsData.pprTotals && this.bpExpendsData.pprTotals.length > 0) {
            const pprTotal = this.bpExpendsData.pprTotals.filter((item: any) => item.ppr === row.ppr)[0];

            if (pprTotal && pprTotal[field1] !== row[field2]) {
                totalSum = pprTotal[field1];
                areThereDiff = true;
            }
            if (!pprTotal && row[field2] > 0) {
                areThereDiff = true;
            }
            if (pprTotal && !pprTotal[field1]  && !row[field2]) {
                areThereDiff = false;
            }
        }
        return { diff: areThereDiff, sum: totalSum };
    }

    public getPprName(ppr: number): string {
        const pprItem = this.commonResources.pprList.find((item: any) => {
            return item.ppr === ppr;
        });
        if (pprItem) {
            return pprItem.name_ru;
        }
        return ppr + "";
    }

    public formatCurrency(currency: number | null | undefined): string {
        if (currency || currency === 0.0) {
            return currency.toLocaleString();
        }
        return '';
    }

    public zeroPad(num: number, places: number): string {
        const zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
    }

    private makeToast(variant: any, title: string, tostbody: any) {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }
}
